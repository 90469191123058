import axios from "axios";

export default {
  namespaced: true,
  state: { recetas: [] },

  mutations: {
    setRecetas(state, recetas) {
      state.recetas = recetas;
    },
    addReceta(state, receta) {
      state.recetas.push(receta);
    },
  },
  actions: {
    async fetchRecetas({ commit }) {
      try {
        const response = await axios.get("/prescription/");
        commit("setRecetas", response.data);
      } catch (error) {
        console.log("Error cargando las recetas", error);
      }
    },
    async addReceta({ commit }, receta) {
      try {
        const response = await axios.post('/prescription/', receta)
        commit('addReceta', response.data)
      } catch (error) {
        console.error('Error adding receta:', error);
      }
    }
  },
};
