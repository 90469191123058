<template>
  <v-container>
    <h2>Encuesta</h2>
    <p class="sub-header-poll">
      A continuación encontrarás 4 preguntas relacionadas con el consumo de
      drogas.
    </p>
    <v-form ref="form" @submit.prevent="siguiente" v-model="valid">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            label="Fecha encuesta"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-model="date"
            v-on="on"
            color="#1dc6d8"
          />
        </template>
        <v-date-picker
          v-model="date"
          :max="
            new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substring(0, 10)
          "
          min="1900-01-01"
          color="#1dc6d8"
        />
      </v-menu>
      <p class="pregunta-poll">
        1. En el último año, ¿qué tan frecuentemente usaste algún tipo de droga
        (como por ejemplo: marihuana, popper, coca, éxtasis, keta, G, etc) o
        alcohol para cambiar la forma en que te sentías?
      </p>
      <v-radio-group
        v-model="encuesta_uso_drogas"
        required
        :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
      >
        <v-radio
          v-for="n in this.OPCIONES_DROGAS"
          :key="n"
          :label="`${n}`"
          :value="n"
          color="#1dc6d8"
        />
      </v-radio-group>
      <p class="pregunta-poll">
        2. En el último año, ¿qué tan frecuentemente usaste más drogas o bebiste
        más alcohol del que habías planeado?
      </p>
      <v-radio-group
        v-model="encuesta_frecuencia_drogas"
        required
        :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
      >
        <v-radio
          v-for="n in this.OPCIONES_DROGAS"
          :key="n"
          :label="`${n}`"
          :value="n"
          color="#1dc6d8"
        />
      </v-radio-group>
      <p class="pregunta-poll">
        3. En el último año, ¿qué tan frecuentemente sentiste que necesitabas o
        querías dejar de consumir drogas o alcohol y no fuiste capaz?
      </p>
      <v-radio-group
        v-model="encuesta_dejar_drogas"
        required
        :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
      >
        <v-radio
          v-for="n in this.OPCIONES_DROGAS"
          :key="n"
          :label="`${n}`"
          :value="n"
          color="#1dc6d8"
        />
      </v-radio-group>
      <p class="pregunta-poll">
        4. En el último año, ¿Qué tan frecuentemente consumiste drogas o alcohol
        para ayudarte a sentirte mejor?
      </p>
      <v-radio-group
        v-model="encuesta_sentir_mejor"
        required
        :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
      >
        <v-radio
          v-for="n in this.OPCIONES_DROGAS"
          :key="n"
          :label="`${n}`"
          :value="n"
          color="#1dc6d8"
        />
      </v-radio-group>
      <p class="pregunta-poll">
        ¿Ha tenido problemas entre usted y su pareja, familiares, amigos o
        laborales a causa del consumo de drogas o alcohol?
      </p>
      <v-radio-group
        v-model="encuesta_problemas_drogas"
        required
        :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
      >
        <v-radio
          v-for="n in this.OPCIONES_DROGAS"
          :key="n"
          :label="`${n}`"
          :value="n"
          color="#1dc6d8"
        />
      </v-radio-group>
      <v-row justify="end">
        <v-col cols="auto">
          <v-btn type="submit" class="golden-button">Guardar</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "DrogasEncuesta",
  data() {
    return {
      menu: false,
      valid: false,
      date: new Date().toISOString().substring(0, 10),
      encuesta_uso_drogas: "",
      encuesta_frecuencia_drogas: "",
      encuesta_dejar_drogas: "",
      encuesta_sentir_mejor: "",
      encuesta_problemas_drogas: "",
      OPCIONES_DROGAS: [
        "Nunca",
        "Menos de una vez al mes",
        "Más de una vez al mes",
        "Al menos una vez a la semana",
        "Al menos una vez al día",
      ],
    };
  },
  methods: {
    async siguiente() {
      this.$refs.form.validate();
      const { valid, encuesta_uso_drogas, encuesta_frecuencia_drogas, encuesta_dejar_drogas, encuesta_sentir_mejor, encuesta_problemas_drogas, date } = this;
      if (valid) {
        const pacienteId = this.$route.params["id"];
        // Accede a la lista de pacientes
        const pacientes = this.$store.state.patients.patients;

        // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
        const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

        const rut = paciente.rut

        const payload = {
                    paciente_nombre: rut,
                    Encuesta_1: encuesta_uso_drogas,
                    Encuesta_2: encuesta_frecuencia_drogas,
                    Encuesta_3: encuesta_dejar_drogas,
                    Encuesta_4: encuesta_sentir_mejor,
                    Encuesta_5: encuesta_problemas_drogas,
                    register_poll_date: date,
                    method: "POST",
                };
        try {
            await this.$store.dispatch("patients/addEncuestaDrugs", payload);
        } catch (error) {
            console.log("Algo falló: ", error);
        }
      } else {
        console.log("Error");
      }
    },
  },
};
</script>
