<template>
  <v-container>
    <h2>Encuesta</h2>
    <p class="sub-header-poll">
      A continuación responde las siguientes preguntas.
    </p>
    <v-form ref="form" @submit.prevent="siguiente" v-model="valid">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            label="Fecha encuesta"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-model="date"
            v-on="on"
            color="#1dc6d8"
          />
        </template>
        <v-date-picker
          v-model="date"
          :max="
            new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substring(0, 10)
          "
          min="1900-01-01"
          color="#1dc6d8"
        />
      </v-menu>
      <p class="pregunta-poll">
        1.Actualmente en relación a tu situación laboral te encuentras
        <span class="sub-pregunta-poll">(Elige solo una alternativa)</span>
      </p>
      <v-radio-group
        v-model="encuesta_laboral"
        required
        :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
      >
        <v-radio
          v-for="n in this.LABORAL"
          :key="n"
          :label="`${n}`"
          :value="n"
          color="#1dc6d8"
        />
      </v-radio-group>
      <p class="pregunta-poll">
        2. En relación a dónde vives:
        <span class="sub-pregunta-poll">(Elige solo una alternativa)</span>
      </p>
      <v-radio-group
        v-model="encuesta_vivienda"
        required
        :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
      >
        <v-radio
          v-for="n in this.VIVIENDA"
          :key="n"
          :label="`${n}`"
          :value="n"
          color="#1dc6d8"
        />
      </v-radio-group>
      <v-row justify="end">
        <v-col cols="auto">
          <v-btn type="submit" class="golden-button">Guardar</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "LaboralEncuesta",
  data() {
    return {
      menu: false,
      valid: false,
      encuesta_laboral: "",
      encuesta_vivienda: "",
      date: new Date().toISOString().substring(0, 10),
      LABORAL: [
        "Trabajo con contrato",
        "Trabajo como independiente con boletas",
        "Trabajo como independiente sin boletas",
        "Trabajo no remunerado (ej. Cuidado de familiares, dueña de casa)",
        "No tengo trabajo/cesante",
        "Soy jubilado/pensionado",
        "Soy estudiante",
        "Soy estudiante y trabajo",
      ],
      VIVIENDA: [
        "Vivienda propia (arrendatario o propietario)",
        "Vivienda colectiva (Albergue, residencia, pensión, etc.)",
        "Allegado",
        "Situación de calle",
      ],
    };
  },
  methods: {
    async siguiente() {
      this.$refs.form.validate();
      const { valid, encuesta_laboral, encuesta_vivienda, date } = this;
      if (valid) {
        const pacienteId = this.$route.params["id"];
        // Accede a la lista de pacientes
        const pacientes = this.$store.state.patients.patients;

        // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
        const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

        const rut = paciente.rut

        const payload = {
                    paciente_nombre: rut,
                    Encuesta_1: encuesta_laboral,
                    Encuesta_2: encuesta_vivienda,
                    register_poll_date: date,
                    method: "POST",
                };
        try {
            await this.$store.dispatch("patients/addEncuestaLabo", payload);
        } catch (error) {
            console.log("Algo falló: ", error);
        }
      } else {
        console.log("Error");
      }
    },
  },
};
</script>
