var render = function render(){var _vm=this,_c=_vm._self._c;return (!this.isLoading)?_c('section',[_c('v-tabs',{attrs:{"fixed-tabs":"","background-color":"indigo","color":"white"},model:{value:(_vm.modelTab),callback:function ($$v) {_vm.modelTab=$$v},expression:"modelTab"}},[_c('v-tab',{attrs:{"exact":true,"to":{
        name: 'registrosCD4',
        params: { id: this.$route.params['id'] },
      }}},[_vm._v(" Registros CD4 ")]),_c('v-tab',{attrs:{"exact":true,"to":{
        name: 'registrosRNA',
        params: { id: this.$route.params['id'] },
      }}},[_vm._v(" Registros RNA ")]),_c('v-tab',{attrs:{"exact":true,"to":{
        name: 'registrosCV',
        params: { id: this.$route.params['id'] },
      }}},[_vm._v(" Registros CV ")])],1),_c('router-view')],1):_c('section',{staticClass:"d-flex flex-column align-center justify-center custom-height"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":50,"color":"primary"}}),_c('h3',[_vm._v("Cargando...")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }