<template>
  <v-app>
    <v-container class="container-safe-area">
      <div>
        <v-row justify="center" align="center"
          ><v-col cols="12" sm="4"><h1>Este es tu perfil</h1></v-col></v-row
        >
        <v-row></v-row>
        <v-divider vertical></v-divider>
        <v-divider></v-divider>
        <v-divider vertical></v-divider>

        <v-row justify="center" align="center">
          <v-col cols="12" sm="4">
            <v-btn @click="activarFormularios">Activar formularios</v-btn>
            <v-col><p v-if="statusForm">
              Formularios activos: <v-icon>mdi-check-circle </v-icon>
            </p>
            <p v-else-if="!statusForm">
              Formularios activos: <v-icon>mdi-close-circle-outline </v-icon>
            </p></v-col>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-app>
</template>
<script>
export default {
  data() {
    return {
      statusForm: null,
    };
  },
  methods: {
    activarFormularios() {
     
      this.statusForm = !this.statusForm;
      this.$store.dispatch("patients/statusForm", this.statusForm);
    },
  },
  created(){
    this.statusForm = this.$store.getters["patients/statusForm"]
  }
};
</script>
