import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  namespaced:true,
  state() {
    return {
      status: 'authenticating', // 'authenticated','not-authenticated', 'authenticating'
      user: null,
      idToken: null,
      refreshToken: null
    };
  },
  mutations,
  actions,
  getters,
};
