<template>
  <section>
    <v-container v-if="!instituciones">
      <div>
        <v-row>
          <v-col
            ><v-row><about /></v-row> <v-row> <support /></v-row
          ></v-col>
          <v-col>
            <v-row class="imagen_logo"> <!-- TODO: Mejorar o eliminar esta clase --> 
              <img :src="require('../assets/images/logoVida.png')" height="500"
              width="500">
            <!-- <v-img
              class="logoVIDA"
              :src="require('../assets/logoVida.png')"
              max-height="500"
              max-width="500"
            ></v-img> -->
          </v-row>
            <v-row class="button-home" v-if="estado != 'authenticated'">
              <v-btn @click="ingresoInstituciones"
                >Ingresar <v-icon>mdi-login</v-icon></v-btn
              >
            </v-row>
            <v-row class="button-home" v-else
              ><v-btn @click="onLogout"
                >Salir <v-icon>mdi-logout</v-icon></v-btn
              ></v-row
            >
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-container v-else>
      <ingreso-instituciones else> </ingreso-instituciones>
    </v-container>
  </section>
</template>

<script>
import About from "../components/aboutHome.vue";
import Support from "../components/supportHome.vue";
import ingresoInstituciones from "../pages/IngresoInstituciones.vue";
export default {
  components: { About, Support, ingresoInstituciones },
  data() {
    return {
      instituciones: false,
    };
  },
  methods: {
    ingresoInstituciones() {
      this.instituciones = true;
    },
    onLogout() {
      this.$store.commit("auth/logout");
      this.$router.replace("/");
    },
  },
  computed: {
    estado() {
      const y = this.$store.getters["auth/currentState"];
      return y;
    },
  },
};
</script>

<style scoped>
.imagen_logo{
  justify-content: center;
}
.logoVIDA {
  margin-top: 0px;
  padding-top: 0px;
  margin-left: 30px;
  padding-right: 0px;
}
.title {
  font-family: "Roboto", sans-serif;
  margin-top: 10px;
  margin-left: 0px;
  padding-left: 0px;
  display: block;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.87);
}
.button-home {
  justify-content: center;
}
</style>
