<template>
  <v-container class="contenedores-monitoreo">
    <v-sheet class="pa-3 my-5 rounded-lg" color="#F0F8F8">
      <v-row>
        <v-col>
          <h2>Variables registradas</h2>
          <p>Verifque si los datos son correctos</p>
        </v-col>
        <v-col> <v-btn>Actualizar</v-btn> </v-col>
      </v-row>
      <v-row>
        <v-col>Codigo Conasida</v-col>
        <v-col>Fecha de nacimiento</v-col>
        <v-col>Edad</v-col>
        <v-col>Segmento etario</v-col>
      </v-row>
      <v-row>
        <v-col>Region</v-col>
        <v-col>Comuna</v-col>
      </v-row>
      <v-row>
        <v-col>Fecha de ingreso a la fundación</v-col>
        <v-col>Fecha de enrolamiento de la fundación</v-col>
      </v-row>
      <v-row>
        <v-col>Paciente derivado desde otro centro</v-col>
        <v-col>Fecha derivación</v-col>
      </v-row>
      <v-row>
        <v-col>Sexo</v-col>
        <v-col>Identidad de genero</v-col>
      </v-row>
      <v-row>
        <v-col>Previsión</v-col>
      </v-row>
      <v-row>
        <v-col>Ha estado en algun ensayo clinico</v-col>
        <v-col>Paciente en estudio</v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn>Guardar</v-btn>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet class="pa-3 my-5 rounded-lg" color="#F0F8F8">
      <v-row>
        <v-col>Orientación sexual</v-col>
        <v-col>Trabajador sexual</v-col>
      </v-row>
      <v-row>
        <v-col>Uso de condon</v-col>
        <v-col>Frecuencia uso de condon</v-col>
      </v-row>
      <v-row>
        <v-col>Nivel educacional</v-col>
        <v-col>Situación laboral</v-col>
      </v-row>
      <v-row>
        <v-col>Situación economica</v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn>Guardar</v-btn>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet class="pa-3 my-5 rounded-lg" color="#F0F8F8">
      <h2>Pareja(s) actual(es)</h2>
      <v-row>
        <v-col>Numero parejas</v-col>
        <v-col>Fecha diagnostico</v-col>
        <v-col>Estado serologico pareja</v-col>
      </v-row>
      <v-row
        ><v-col><v-btn>Guardar</v-btn></v-col>
      </v-row>
    </v-sheet>
    <!--  <v-form @submit.prevent="saveData_Fecha_ingreso_FA">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            label="Fecha_ingreso_FA"
            prepend-icon="mdi-calendar"
            v-bind="attrs"
            v-on="on"
            v-model="fecha_ingreso_FA"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="fecha_ingreso_FA"
          :max="
            new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10)
          "
          min="1950-01-01"
          @input="menu = false"
        ></v-date-picker>
      </v-menu>

      <v-btn
        color="#F4A261"
        class="white--text rounded-pill"
        @click="saveData_Fecha_ingreso_FA"
        >GUARDAR</v-btn
      >
    </v-form> -->
  </v-container>
</template>
<script>
export default {
  name: "InfoPacienteComponente",
};
</script>
<style>
.contenedores-monitoreo {
  padding: 20px;
}
</style>
