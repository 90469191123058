/* export default{
    userPermission(state){
        return state.userPermission
    }
} */
export default{
    currentState( state ){
        return state?.status || ''
    },
    username( state ){
        return state.user?.name || ''
    },
    autorizacion( state ){
        return state.user?.tipo || ''
    },
}