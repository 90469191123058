<template>
  <v-container class="contenedores-monitoreo">
    <v-sheet class="pa-3 my-5 rounded-lg" color="#F0F8F8">
      <v-row>
        <v-col>
          <h2>Diagnóstico</h2>
          <p>Ingresa los datos que falten</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>Fecha diagnostico VIH</v-col>
        <v-col>Diagnostico VIH</v-col>
      </v-row>
      <v-row>
        <v-col> Mecanismo de infeccion </v-col>
        <v-col>Modo de transmision</v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center">
          <v-btn>Guardar</v-btn>
        </v-col>
      </v-row>
    </v-sheet>

    <v-sheet class="pa-3 my-5 rounded-lg" color="#F0F8F8">
      <v-col>
        <v-row>
          <h3>Etapa CD4</h3>
        </v-row>
        <v-row>
          <v-col>Fecha</v-col>
          <v-col>Etapa CD4</v-col>
          <v-col class="d-flex justify-space-around">
            <v-btn>Guardar</v-btn>
            <v-btn>Ver más</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <h3>Etapa SIDA</h3>
        </v-row>
        <v-row>
          <v-col>Fecha</v-col>
          <v-col>Etapa Sida</v-col>
          <v-col class="d-flex justify-space-around">
            <v-btn>Guardar</v-btn>
            <v-btn>Ver más</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-sheet>
    <v-sheet class="pa-3 my-5 rounded-lg" color="#F0F8F8">
      <v-col>
        <v-row>
          <h3>Enfermedades oportunistas</h3>
        </v-row>
        <v-row>
          <v-col>Fecha</v-col>
          <v-col>Enfermedad oportunista</v-col>
          <v-col>Tipo especifico</v-col>
          <v-col class="d-flex justify-space-around">
            <v-btn>Guardar</v-btn>
            <v-btn>Ver más</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-sheet>
  </v-container>
</template>
<script>
export default {
  name: "DiagnosticoComponente",
};
</script>

<style>
.contenedores-monitoreo {
  padding: 20px;
}
</style>
