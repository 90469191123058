<template>
  <div>
    <v-tabs
      v-model="tab"
      color="white"
      slider-color="white"
      background-color="#1dc6d8"
      centered
    >
      <v-tab>Registros CD4</v-tab>
      <v-tab>Registros RNA</v-tab>
      <v-tab>Registros CV</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <RegistrosCD4Componente v-if="tab === 0" />
      </v-tab-item>
      <v-tab-item>
        <RegistrosRNAComponente v-if="tab === 1" />
      </v-tab-item>
      <v-tab-item>
        <RegistrosCVComponente v-if="tab === 2" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import registrosCD4 from "@/components/forms/registrosCD4.vue";
import registrosRNA from "@/components/forms/registrosRNA.vue";
import registrosCV from "@/components/forms/registrosCV.vue";
export default {
  components: {
    RegistrosCD4Componente: registrosCD4,
    RegistrosRNAComponente: registrosRNA,
    RegistrosCVComponente: registrosCV,
  },
  name: "LaboratorioComponente",
  data() {
    return {
      tab: null,
      items: [
        {
          tab: "Registros CD4",
          content: "RegistrosCD4Componente",
        },
        {
          tab: "Registros RNA",
          content: "RegistrosRNAComponente",
        },
        {
          tab: "Registros CV",
          content: "RegistrosCVComponente",
        },
      ],
    };
  },
};
</script>
