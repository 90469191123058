import { render, staticRenderFns } from "./supportHome.vue?vue&type=template&id=9888fb2c&scoped=true&"
import script from "./supportHome.vue?vue&type=script&lang=js&"
export * from "./supportHome.vue?vue&type=script&lang=js&"
import style0 from "./supportHome.vue?vue&type=style&index=0&id=9888fb2c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9888fb2c",
  null
  
)

export default component.exports