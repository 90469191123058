<!-- components/AppToast.vue -->

<template>
  <v-snackbar v-model="show" :color="color" :timeout="5000" top right>
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="hideToast">Close</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "AppToast",
  computed: {
    ...mapState("toast", ["message", "color"]),
    show: {
      get() {
        return this.$store.state.toast.show;
      },
      set(value) {
        if (!value) {
          this.hideToast();
        }
      },
    },
  },
  methods: {
    ...mapActions("toast", ["hideToast"]),
  },
};
</script>
