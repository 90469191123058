<template>
  <div>
    <v-tabs
      v-model="tab"
      color="white"
      slider-color="white"
      background-color="primary"
      centered
    >
      <v-tab>Tratamiento Antiretroviral (TAR)</v-tab>
      <v-tab>Tratamiento Patologias Oportunistas (POP)</v-tab>
      <v-tab>Entrega/Retiros Medicamentos</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <TratamientoTAR v-if="tab === 0" />
      </v-tab-item>
      <v-tab-item>
        <TratamientoPOP v-if="tab === 1" />
      </v-tab-item>
      <v-tab-item>
        <EntregaMedicamentos v-if="tab === 2" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import TratamientoTAR from "@/components/forms/TratamientoTAR.vue";
import tratamientoPOP from "@/components/forms/tratamientoPOP.vue";
import entregaRetiros from "@/components/forms/entregaRetiros.vue";
export default {
  components: {
    TratamientoTAR,
    TratamientoPOP: tratamientoPOP,
    EntregaMedicamentos: entregaRetiros,
  },
  name: "TratamientoComponente",
  data() {
    return {
      tab: null,
      items: [
        {
          tab: "Tratamiento Antiretroviral (TAR)",
          content: "TratamientoTar",
        },
        {
          tab: "Tratamiento Patologias Oportunistas (POP)",
          content: "TratamientoPOP",
        },
        {
          tab: "Entrega/Retiros Medicamentos",
          content: "EntregaMedicamentos",
        },
      ],
    };
  },
};
</script>
