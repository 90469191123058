<template>
  <div v-if="isVisible" class="dialog-overlay">
    <div class="dialog-box">
      <h3>{{ dialogData.title }}</h3>
      <p>{{ dialogData.message }}</p>
      <button @click="confirm">Confirmar</button>
      <button @click="close">Cancelar</button>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isVisible() {
      return this.$store.getters["dialog/isVisible"];
    },
    dialogData() {
      return this.$store.getters["dialog/dialogData"];
    },
  },
  methods: {
    confirm() {
      if (this.dialogData.onConfirm) {
        this.dialogData.onConfirm();
      }
      this.$store.dispatch("dialog/hideDialog");
    },
    close() {
      this.$store.dispatch("dialog/hideDialog");
    },
  },
};
</script>

<style>
/* Aquí puedes poner tus estilos para el diálogo */
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-box {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}
</style>
