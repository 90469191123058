/* export default{
    loginAs(context,payload){
        context.commit('setLoginAs',payload)
    },
    proto_password(context, payload){
        if(payload==="WIC_vida_22"){
            localStorage.setItem('userPermission2', true);
            context.commit('setAuth',true)
        }else{
            console.log("Bad Password")
        }
        
        
    }
} */

import authApi from "@/api/authApi"
// export const myAction = async ({ commit }) => {


// }

export default {
    async createUser(context, user) {
    const {name, email, password, tipo} = user

    try {
        const {data} = await authApi.post(':signUp',{email, password, returnSecureToken:true})
        const {idToken, refreshToken} = data
        await authApi.post(':update', { displayName: name, photoUrl: tipo, idToken, refreshToken })
        
        delete user.password
        //console.log(user);
        context.commit("loginUser", {user, idToken, refreshToken})

        return {ok:true}

    } catch (error) {
        return {ok:false, message:error.response.data.error.message}
    }

},
async signinUser(context, user) {
    const {email, password} = user

    try {
        const {data} = await authApi.post(':signInWithPassword',{email, password, returnSecureToken:true})
        const { displayName, idToken, refreshToken } = data
        
        user.name = displayName
        context.commit("loginUser", {user, idToken, refreshToken})

        return {ok:true}

    } catch (error) {
        return {ok:false, message:error.response.data.error.message}
    }

},

async checkAuthentication (context) {

    const idToken      = localStorage.getItem('idToken')
    const refreshToken = localStorage.getItem('refreshToken')

    if( !idToken ) {
        context.commit('logout')
        return { ok: false, message: 'No hay token' }
    }

    try {
        
        const { data } = await authApi.post(':lookup', { idToken })
        const { displayName, email, photoUrl } = data.users[0]

        const user = {
            name: displayName,
            tipo: photoUrl,
            email
        }

        context.commit('loginUser', { user, idToken, refreshToken })

        return { ok: true }

    } catch (error) {
        context.commit('logout')
        return { ok: false, message: error.response.data.error.message }
    }

}


}