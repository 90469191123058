<template>
  <div class="navegador">
    <div class="botones">
      <ul>
        <li class="logoVIDA">
          <img class="logoVIDA" :src="logo" height="50" width="50" />
        </li>
        <li class="logo">VIDA</li>
        <li>
          <router-link :to="{ name: 'home', params: { home: false } }">
            Home
          </router-link>
        </li>
        <li v-if="estado === 'authenticated'">
          <router-link to="/agregarPaciente">+Agregar Paciente</router-link>
        </li>
        <li v-if="estado === 'authenticated'">
          <router-link :to="'/pacientes'">Pacientes</router-link>
        </li>
        <li v-if="(estado === 'authenticated') & (patient === 'Admin')">
          <router-link :to="'/register'">Crear Usuario</router-link>
        </li>
        <li class="usuario" v-if="estado === 'authenticated'">{{ patient }}</li>
        <span>
          <!--TODO agregar estos valores al final del navbar-->
          <li class="usuario" v-if="user">
            <router-link :to="{ name: 'profile' }">{{ user }}</router-link>
          </li>

          <li
            @click="onLogout"
            class="usuario"
            v-if="estado === 'authenticated'"
          >
            <a> Logout</a>
          </li>
        </span>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logo: require("../../assets/images/logoVida.png"),
    };
  },
  methods: {
    onLogout() {
      this.$store.commit("auth/logout");
      this.$router.replace("/");
    },
  },
  computed: {
    patient() {
      return this.$store.getters["auth/autorizacion"];
    },
    user() {
      const x = this.$store.getters["auth/username"];
      return x;
    },
    estado() {
      const y = this.$store.getters["auth/currentState"];
      return y;
    },
  },
};
</script>
<style scoped>
.navegador {
  width: 100%;
  overflow: hidden;
  height: 4rem;
  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: center;
}
.usuario {
  float: right;
}
.button {
  background-color: black;
}
.botones {
  width: 100%;
  float: right;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: rgb(29, 196, 216);
  display: flex;
  align-items: center;
}
button {
  padding: 0px;
  margin: 0px;
  font-weight: normal;
}
li {
  margin-top: 7px;
  display: flex;
  float: left;
  color: white;
  display: block;
  text-align: center;
  justify-content: center;
  padding: 0.5rem;
  font-weight: bold;
}
.logo {
  padding-left: 0px;
  margin-top: 5px;
  font-size: 1.2rem;
}
.logoVIDA {
  margin-top: 0px;
  padding-top: 0px;
}

a {
  text-decoration: None;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  color: white;
  font-weight: normal;
  padding: 0.8rem 0.8rem;
}
button:hover,
a:hover,
a:active,
a.router-link-exact-active {
  font-weight: bold;
  border: solid white 2px;
}
</style>
