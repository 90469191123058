<template>
  <section>
    <template>
      <v-card class="mx-auto" tile>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              ><h1>Paciente {{ id }}</h1>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-col class="lesspadding">
          <h3>
            <v-icon v-if="!showFastTrack" @click="clickShow('fasttrack')">mdi-eye</v-icon>
            <v-icon v-else @click="clickShow('fasttrack')">mdi-eye-off</v-icon>
            <router-link
              :to="{
                name: 'pacienteFastTrack',
                params: { id: this.id },
              }"
            >
              Predicción Fast Track:</router-link
            >
          </h3>
          <v-list-item-subtitle v-if="showFastTrack">
            <v-row>
              <v-col
                ><h3>Intervención:</h3>
                <p v-if="patientVariables.clasificacion_ft">{{ patientVariables.clasificacion_ft }}</p>
                <p v-else>No hay predicción aún</p></v-col
              >
            </v-row>

            <!-- <v-progress-circular
                v-if="adherenciaFastTrack === 'Cargando...'"
                indeterminate
                color="primary"
              ></v-progress-circular> -->
          </v-list-item-subtitle>
          <!------ Farmacia ------->

          <h3>
            <v-icon v-if="!showFarmacia" @click="clickShow('farmacia')">mdi-eye</v-icon>
            <v-icon v-else @click="clickShow('farmacia')">mdi-eye-off</v-icon>
            <router-link
              :to="{
                name: 'Farmacia',
                params: { id: this.id },
              }"
            >
              Prediccion Farmacia:</router-link
            >
          </h3>
          <v-list-item-subtitle>
            <ul v-if="showFarmacia">
              <li v-for="i in predictionFarmaciaData" :key="i.year">
                {{ i.year }} - {{ Object.values(i.label)[0] }} - ({{ Object.keys(i.label)[0] }}) -
                {{ i.probabilidad.toFixed(2)
                }}<!-- {{ (i.probabilidad * 100).toFixed(3) }}% -->
              </li>
            </ul>
          </v-list-item-subtitle>
          <!------ Eco ------->
          <h3>
            <v-icon v-if="!showEcosistema" @click="clickShow('ecosistema')">mdi-eye</v-icon>
            <v-icon v-else @click="clickShow('ecosistema')">mdi-eye-off</v-icon>
            <router-link
              :to="{
                name: 'Ecosistema',
                params: { id: this.id },
              }"
            >
              Prediccion Ecosistema:</router-link
            >
          </h3>
          <v-list-item-subtitle>
            <ul v-if="showEcosistema">
              <li v-for="i in predictionEcosistemaData" :key="i.year">
                {{ i.year }} - {{ Object.values(i.label)[0] }} - ({{ Object.keys(i.label)[0] }}) -
                {{ i.probabilidad.toFixed(2)
                }}<!-- {{ (i.probabilidad * 100).toFixed(3) }}% -->
              </li>
            </ul>
          </v-list-item-subtitle>
          <!-----Depresion------>
          <h3>
            <v-icon v-if="!showDepresion" @click="clickShow('depresion')">mdi-eye</v-icon>
            <v-icon v-else @click="clickShow('depresion')">mdi-eye-off</v-icon>
            <router-link
              :to="{
                name: 'Depresion',
                params: { id: this.id },
              }"
            >
              Prediccion Depresion:</router-link
            >
          </h3>
          <v-list-item-subtitle>
            <ul v-if="showDepresion">
              <li v-for="i in predictionDepresiónData" :key="i.year">
                {{ i.year }} - {{ Object.values(i.label)[0] }} - ({{ Object.keys(i.label)[0] }}) -
                {{ i.probabilidad.toFixed(2)
                }}<!-- {{ (i.probabilidad * 100).toFixed(3) }}% -->
              </li>
            </ul>
          </v-list-item-subtitle>
          <!----- Alcohol y otras drogas--->
          <h3>
            <v-icon v-if="!showAyD" @click="clickShow('ayd')">mdi-eye</v-icon>
            <v-icon v-else @click="clickShow('ayd')">mdi-eye-off</v-icon>
            <router-link
              :to="{
                name: 'Alcohol',
                params: { id: this.id },
              }"
            >
              Prediccion Alcohol y otros Drogas:</router-link
            >
          </h3>
          <v-list-item-subtitle>
            <ul v-if="showAyD">
              <li v-for="i in predictionAyDData" :key="i.year">
                {{ i.year }} - {{ Object.values(i.label)[0] }} - ({{ Object.keys(i.label)[0] }}) -
                {{ i.probabilidad.toFixed(2)
                }}<!-- {{ (i.probabilidad * 100).toFixed(3) }}% -->
              </li>
            </ul>
          </v-list-item-subtitle>
        </v-col>
      </v-card>
    </template>
  </section>
</template>
<script>
export default {
  data() {
    return {
      showFarmacia: true,
      showEcosistema: true,
      showDepresion: true,
      showAyD: true,
      showFastTrack: true,
      rut: null,
      predictionEcosistemaData: null,
      predictionFarmaciaData: null,
      predictionDepresiónData: null,
      predictionAyDData: null,
    };
  },
  props: ["id"],
  methods: {
    clickShow(predictor) {
      if (predictor === "farmacia") {
        this.showFarmacia = !this.showFarmacia;
      } else if (predictor === "ecosistema") {
        this.showEcosistema = !this.showEcosistema;
      } else if (predictor === "depresion") {
        this.showDepresion = !this.showDepresion;
      } else if (predictor === "ayd") {
        this.showAyD = !this.showAyD;
      } else if (predictor === "fasttrack") {
        this.showFastTrack = !this.showFastTrack;
      }
    },
    async loadPredictions() {
      await this.$store.dispatch("patients/loadPredictions", this.rut);
    },
    //Get Predictions
    //Ecosistema
    predictionEcosistema() {
      const value = this.$store.getters["patients/patientPredictions"];
      let predicciones = value;
      predicciones = predicciones.filter((el) => {
        return el.predictor == "Ecosistema";
      });
      predicciones.sort(function (a, b) {
        return a.year - b.year;
      });
      return predicciones;
    },
    //Farmacia
    predictionFarmacia() {
      const value = this.$store.getters["patients/patientPredictions"];
      let predicciones = value;
      predicciones = predicciones.filter((el) => {
        return el.predictor == "Farmacia";
      });
      predicciones.sort(function (a, b) {
        return a.year - b.year;
      });
      return predicciones;
    },
    predictionDepresion() {
      const value = this.$store.getters["patients/patientPredictions"];
      let predicciones = value;
      predicciones = predicciones.filter((el) => {
        return el.predictor == "Depresión";
      });
      predicciones.sort(function (a, b) {
        return a.year - b.year;
      });
      return predicciones;
    },
    //AyD
    predictionAyD() {
      const value = this.$store.getters["patients/patientPredictions"];
      let predicciones = value;
      predicciones = predicciones.filter((el) => {
        return el.predictor == "AyD";
      });
      predicciones.sort(function (a, b) {
        return a.year - b.year;
      });
      return predicciones;
    },
    //Variables
    async loadVariables() {
      const valores = await this.$store.dispatch("patients/loadVariablesData", {
        idPatient: this.$route.params["id"],
      });
      this.rut = valores.rut;
    },
  },
  computed: {
    patientVariables() {
      return this.$store.getters["patients/patientVariables"];
    },
  },
  async created() {
    await this.loadVariables().then(() => {
      this.loadPredictions().then(() => {
        this.predictionEcosistemaData = this.predictionEcosistema();
        this.predictionFarmaciaData = this.predictionFarmacia();
        this.predictionDepresiónData = this.predictionDepresion();
        this.predictionAyDData = this.predictionAyD();
      });
    });
  },
};
</script>

<style scoped>
.lesspadding {
  padding-top: 0px;
}
.lessbottompadding {
  padding-bottom: 0px;
}
</style>
