<template>
  <v-expansion-panels accordion>
    <v-expansion-panel>
      <v-expansion-panel-header class="custom-header title">
        <div>1. Encuesta Información paciente</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <!-- Información Paciente -->
        <InfoPacienteEncuesta />
        <!-------------------------->
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header class="title">
        2. Encuesta Situación laboral y habitacional
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <!-- Laboral -->
        <LaboralEncuesta />
        <!------------->
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header class="custom-header title">
        3. Encuesta Sobre el tratamiento
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <!-- Tratamiento -->
        <TratamientoEncuesta />
        <!----------------->
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header class="title">
        4. Encuesta Trastornos del animo
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <!-- Componente Trastornos -->
        <TrastornosAnimoEncuesta />
        <!--------------------------->
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header class="custom-header title">
        5. Encuesta Drogas
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <!-- Componente Drogas -->
        <DrogasEncuesta />
        <!----------------------->
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header class="title">
        6. Condición especial - Observaciones
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <ObservacionesEncuesta />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import DrogasEncuesta from "./encuestas/DrogasEncuesta.vue";
import InfoPacienteEncuesta from "./encuestas/InfoPacienteEncuesta.vue";
import LaboralEncuesta from "./encuestas/LaboralEncuesta.vue";
import ObservacionesEncuesta from "./encuestas/ObservacionesEncuesta.vue";
import TrastornosAnimoEncuesta from "./encuestas/TrastornosAnimoEncuesta.vue";
import TratamientoEncuesta from "./encuestas/TratamientoEncuesta.vue";
export default {
  components: {
    InfoPacienteEncuesta,
    LaboralEncuesta,
    TrastornosAnimoEncuesta,
    TratamientoEncuesta,
    DrogasEncuesta,
    ObservacionesEncuesta,
  },
  name: "EncuestasComponente",
};
</script>
<style>
.title {
  color: #868686;
  font-weight: 600;
}
.custom-header {
  background-color: #f1f9f9; /* Color de fondo */
}
</style>
