<template>
    <section>
        <h1>Ficha Clinica</h1>
        <v-form @submit.prevent="saveData_Fecha_ingreso_FA">
            <v-row>
                <v-col>
                    <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                label="Fecha_ingreso_FA"
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                                v-model="fecha_ingreso_FA"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="fecha_ingreso_FA"
                            :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @input="menu = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_Fecha_ingreso_FA">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_Fecha_nacimiento">
            <!--- Fecha_nacimiento---->
            <v-row>
                <v-col>
                    <v-menu
                        v-model="menu_nacimiento"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                label="Fecha_nacimiento"
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                                v-model="Fecha_nacimiento"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="Fecha_nacimiento"
                            :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                            min="1930-01-01"
                            @input="menu_nacimiento = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_Fecha_nacimiento">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_sexo">
            <!--- SEXO---->
            <v-row>
                <v-col>
                    <v-select
                        class="modify-input"
                        required
                        :rules="[(v) => !!v || 'Falta Sexo']"
                        :items="['F', 'M']"
                        label="Sexo"
                        outlined
                        v-model="sexo"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_sexo">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_Fecha_enrolamiento">
            <v-row>
                <v-col>
                    <v-menu
                        v-model="menu_enrolamiento"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                label="Fecha_enrolamiento"
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                                v-model="Fecha_enrolamiento"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="Fecha_enrolamiento"
                            :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @input="menu_enrolamiento = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_Fecha_enrolamiento">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_Fecha_diagnosis">
            <v-row>
                <v-col>
                    <v-menu
                        v-model="menu_diagnosis"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                label="Fecha_diagnosis"
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                                v-model="Fecha_diagnosis"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="Fecha_diagnosis"
                            :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @input="menu_diagnosis = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_Fecha_diagnosis">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_mode">
            <!--- Mode---->
            <v-row>
                <v-col>
                    <v-select
                        class="modify-input"
                        required
                        :rules="[(v) => !!v || 'Falta Mode']"
                        :items="items_mode_2"
                        item-text="descripcion"
                        item-value="mode"
                        label="Mode"
                        outlined
                        v-model="mode"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_mode">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_mode_oth">
            <!--- Mode Oth---->
            <v-row>
                <v-col>
                    <v-select
                        class="modify-input"
                        required
                        :rules="[(v) => !!v || 'Falta Mode Other']"
                        :items="items_mode_oth"
                        item-text="traduccion"
                        item-value="original"
                        label="Mode_oth"
                        outlined
                        v-model="mode_oth"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_mode_oth">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_art_baseline">
            <v-row>
                <v-col>
                    <v-select
                        class="modify-input"
                        required
                        :rules="[(v) => !!v || 'Falta ART baseline']"
                        :items="['CON TAR', 'SIN TAR']"
                        label="Art_baseline"
                        outlined
                        v-model="art_baseline"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_art_baseline">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_cdcstage">
            <v-row>
                <v-col>
                    <v-select
                        class="modify-input"
                        required
                        :rules="[(v) => !!v || 'Falta cdcstage']"
                        :items="items_cdc"
                        label="cdcstage"
                        outlined
                        v-model="cdcstage"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_cdcstage">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_aids">
            <v-row>
                <v-col>
                    <v-select
                        class="modify-input"
                        required
                        :rules="[(v) => !!v || 'Falta aids_y']"
                        :items="['1.0', '2.0', '9.0']"
                        label="aids_y"
                        outlined
                        v-model="aids_y"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_aids">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_clinicaltrial">
            <v-row>
                <v-col>
                    <v-select
                        class="modify-input"
                        required
                        :rules="[(v) => !!v || 'Falta clinicaltrial']"
                        :items="['0.0', '1.0', '2.0']"
                        label="clinicaltrial"
                        outlined
                        v-model="clinicaltrial"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_clinicaltrial">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_death_d">
            <v-row>
                <v-col>
                    <v-menu
                        v-model="menu_death"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                label="death_d"
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                                v-model="death_d"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="death_d"
                            :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @input="menu_death = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_death_d">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_drop_d">
            <v-row>
                <v-col>
                    <v-menu
                        v-model="menu_drop"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                label="drop_d"
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                                v-model="drop_d"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="drop_d"
                            :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @input="menu_drop = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_drop_d">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_genero">
            <v-row>
                <v-col>
                    <v-select
                        class="modify-input"
                        required
                        :rules="[(v) => !!v || 'Falta genero']"
                        :items="['Cis', 'Trans', 'Otro']"
                        label="Genero"
                        outlined
                        v-model="genero"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_genero">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <h1>Farmacia</h1>
        <v-form @submit.prevent="saveData_policlinico">
            <v-row>
                <v-col>
                    <v-select
                        class="modify-input"
                        required
                        :rules="[(v) => !!v || 'Falta genero']"
                        :items="['PVVIH', 'Otro']"
                        label="Policlinico"
                        outlined
                        v-model="policlinico"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_policlinico">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_inicio">
            <v-row>
                <v-col>
                    <v-menu
                        v-model="menu_inicio"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                label="inicio_tar"
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                                v-model="inicio_tar"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="inicio_tar"
                            :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @input="menu_inicio = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_inicio">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_condicion_especial">
            <v-row>
                <v-col>
                    <v-select
                        class="modify-input"
                        required
                        :rules="[(v) => !!v || 'Falta condicion especial']"
                        :items="condicionEspecialOptions"
                        item-value="value"
                        item-text="text"
                        label="Condicion especial"
                        outlined
                        v-model="condicion_especial"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_condicion_especial">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_hla">
            <v-row>
                <v-col>
                    <v-select
                        class="modify-input"
                        required
                        :rules="[(v) => !!v || 'Falta hla']"
                        :items="['Positivo', 'Negativo', 'Desconocido']"
                        label="Hla"
                        outlined
                        v-model="hla"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_hla">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_matrona">
            <v-row>
                <v-col>
                    <v-select
                        class="modify-input"
                        required
                        :rules="[(v) => !!v || 'Falta matrona']"
                        :items="[
                            { text: 'Sí', value: true }, 
                            { text: 'No', value: false }, 
                            { text: 'Desconocido', value: null }
                        ]"
                        label="Matrona"
                        outlined
                        v-model="Matrona"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_matrona">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <h1>Ecosistema Pacientes</h1>
        <v-form @submit.prevent="saveData_prevision">
            <v-row>
                <v-col>
                    <v-select
                        class="modify-input"
                        required
                        :rules="[(v) => !!v || 'Falta prevision']"
                        :items="[
                            { text: 'Fonasa A', value: 'Fonasa A' },
                            { text: 'Fonasa B', value: 'Fonasa B' },
                            { text: 'Fonasa C', value: 'Fonasa C' },
                            { text: 'Fonasa D', value: 'Fonasa D' },
                            { text: 'Prais', value: 'Prais' },
                            { text: 'Prais/A', value: 'Prais/A' },
                            { text: 'Prais/B', value: 'Prais/B' },
                            { text: 'Prais/C', value: 'Prais/C' },
                            { text: 'Prais/D', value: 'Prais/D' },
                            { text: 'Dipreca', value: 'Dipreca' },
                            { text: 'Bloqueado', value: 'Bloqueado' },
                            { text: 'Isapre', value: 'Isapre' },
                            { text: 'SIN DATO', value: 'SIN DATO' }
                        ]"
                        label="Prevision"
                        outlined
                        v-model="Prevision"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_prevision">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_geo">
            <v-row>
                <v-col>
                    <v-select
                    v-model="selectedRegion"
                    :rules="[(v) => !!v || 'Falta región']"
                    :items="regions"
                    label="Región"
                    @change="onRegionChange"
                    outlined
                    required
                    ></v-select>
                </v-col>
                <v-col>
                    <v-select
                    v-model="selectedComuna"
                    :items="filteredComunas"
                    :rules="[(v) => !!v || 'Falta comuna']"
                    label="Comuna"
                    outlined
                    required
                    :disabled="!selectedRegion"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_geo">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_niveledu">
            <v-row>
                <v-col>
                    <v-select
                        class="modify-input"
                        required
                        :rules="[(v) => !!v || 'Falta Nivel Educacional']"
                        :items="[
                            { text: 'ANALFABETO', value: 'ANALFABETO' },
                            { text: 'BASICA', value: 'BASICA' },
                            { text: 'MEDIA', value: 'MEDIA' },
                            { text: 'TECNICO', value: 'TECNICO' },
                            { text: 'UNIVERSITARIA', value: 'UNIVERSITARIA' },
                            { text: 'SIN DATO', value: 'SIN DATO' },
                        ]"
                        label="Nivel Educacional"
                        outlined
                        v-model="nivel_educacional"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_niveledu">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_educacion">
            <v-row>
                <v-col>
                    <v-select
                        class="modify-input"
                        required
                        :rules="[(v) => !!v || 'Falta Educación']"
                        :items="[
                            { text: 'COMPLETA', value: 'COMPLETA' },
                            { text: 'INCOMPLETA', value: 'INCOMPLETA' },
                        ]"
                        label="Educacion"
                        outlined
                        v-model="educacion"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_educacion">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_empleo_descri">
            <v-row>
                <v-col>
                    <v-select
                        class="modify-input"
                        required
                        :rules="[(v) => !!v || 'Falta Descripción Empleo']"
                        :items="[
                            { text: 'ACTIVO', value: 'ACTIVO' },
                            { text: 'CESANTE', value: 'CESANTE' },
                            { text: 'PASIVO', value: 'PASIVO' },
                            { text: 'SIN DATO', value: 'SIN DATO' },
                        ]"
                        label="Descripción Empleo"
                        outlined
                        v-model="empleo_descri"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_empleo_descri">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_empleo">
            <v-row>
                <v-col>
                <v-text-field
                    class="modify-input"
                    required
                    :rules="[
                    (v) => !!v || 'Falta Empleo',
                    (v) => /^[a-zA-ZÀ-ÿ\s]+$/.test(v) || 'Solo se permiten letras, espacios y tildes'
                    ]"
                    label="Empleo"
                    outlined
                    v-model="empleo"
                ></v-text-field>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_empleo">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_hiv_patner">
            <v-row>
                <v-col>
                    <v-select
                        class="modify-input"
                        required
                        :rules="[(v) => !!v || 'Falta hiv partner']"
                        :items="[
                            { text: 'VIH POSITIVO', value: 1 },
                            { text: 'VIH NEGATIVO', value: 2 },
                            { text: 'SIN PAREJA', value: 3 },
                            { text: 'MULTIPLES PAREJAS', value: 4 },
                            { text: 'SIN DATO', value: 0 },
                        ]"
                        label="Hiv Partner"
                        outlined
                        v-model="hiv_partner"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_hiv_patner">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_sex_com">
            <v-row>
                <v-col>
                    <v-select
                        class="modify-input"
                        required
                        :rules="[(v) => !!v || 'Falta sexual commerce']"
                        :items="[
                            { text: 'SI', value: 1 },
                            { text: 'NO', value: 2 },
                            { text: 'SIN DATO', value: 0 },
                        ]"
                        label="Sexual Commerce"
                        outlined
                        v-model="sexual_commerce"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_sex_com">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_use_condom">
            <v-row>
                <v-col>
                    <v-select
                        class="modify-input"
                        required
                        :rules="[(v) => !!v || 'Falta use of condom']"
                        :items="[
                            { text: 'SI', value: 1 },
                            { text: 'NO', value: 2 },
                            { text: 'SIN DATO', value: 0 },
                        ]"
                        label="Use of condom"
                        outlined
                        v-model="use_of_condom"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_use_condom">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_freq_use_condom">
            <v-row>
                <v-col>
                    <v-select
                        class="modify-input"
                        required
                        :rules="[(v) => !!v || 'Falta frequency use of condom']"
                        :items="[
                            { text: 'Nunca', value: 1 },
                            { text: 'Rara vez', value: 2 },
                            { text: 'Mayoria de las veces', value: 3 },
                            { text: 'Siempre', value: 4 },
                            { text: 'Sin Datos', value: 0 },
                        ]"
                        label="Frequency use of condom"
                        outlined
                        v-model="freq_use_of_condom"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_freq_use_condom">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_adquisicion">
            <v-row>
                <v-col>
                    <v-select
                        class="modify-input"
                        required
                        :rules="[(v) => !!v || 'Falta adquisición']"
                        :items="[
                            { text: 'Sexual', value: 1 },
                            { text: 'Transmsión Sexual', value: 2 },
                            { text: 'No Sabe/No Responde', value: 3 },
                            { text: 'IVDU', value: 4 },
                            { text: 'Ocupacional', value: 5 },
                            { text: 'Sin Datos', value: 0 },
                        ]"
                        label="Adquisición"
                        outlined
                        v-model="Adquisicion"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_adquisicion">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_condusexual">
            <v-row>
                <v-col>
                    <v-select
                        class="modify-input"
                        required
                        :rules="[(v) => !!v || 'Falta genero']"
                        :items="['SIN DATO', 'HOMOSEXUAL', 'HETEROSEXUAL', 'BISEXUAL']"
                        label="Condusexual"
                        outlined
                        v-model="Condusexual"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_condusexual">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_autorizado">
            <v-row>
                <v-col>
                    <v-select
                        class="modify-input"
                        required
                        :rules="[(v) => !!v || 'Falta autorizado']"
                        :items="['Falso', 'Verdadero']"
                        label="Tiene autorizado?"
                        outlined
                        v-model="autorizado"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_autorizado">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-form @submit.prevent="saveData_Fecha_muerte">
            <v-row>
                <v-col>
                    <v-menu
                        v-model="menu_muerte"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                label="Fecha muerte"
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                                v-model="fecha_muerte"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="fecha_muerte"
                            :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @input="menu_muerte = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-2">
                <v-col cols="auto">
                    <v-btn color="#F4A261" class="white--text rounded-pill" @click="saveData_Fecha_muerte">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </section>
</template>

<script>
export default {
    data() {
        return {
            alert: false,
            prediction: null,
            menu: false,
            menu_nacimiento: false,
            menu_enrolamiento: false,
            menu_diagnosis: false,
            menu_death: false,
            menu_drop: false,
            menu_inicio: false,
            menu_muerte: false,
            patientAuditorias: [{ numtar: 0 }],
            fecha_ingreso_FA: '',
            Fecha_nacimiento: '',
            fecha_muerte: '',
            sexo: '',
            Fecha_enrolamiento: '',
            Fecha_diagnosis: '',
            mode: '',
            mode_oth: '',
            art_baseline: '',
            cdcstage: '',
            aids_y: '',
            clinicaltrial: '',
            death_d: '',
            drop_d: '',
            genero: '',
            policlinico: '',
            inicio_tar: '',
            condicion_especial: '',
            hla: '',
            Matrona: '',
            Prevision: '',
            nivel_educacional: '',
            educacion: '',
            empleo_descri: '',
            empleo: '',
            hiv_partner: '',
            sexual_commerce: '',
            use_of_condom: '',
            freq_use_of_condom: '',
            Adquisicion: '',
            Condusexual: '',
            autorizado: '',
            condicionEspecialOptions: [
                { value: 0, text: 'No' },
                { value: 1, text: 'Dipreca' },
                { value: 2, text: 'PPL' },
                { value: 3, text: 'Hospitalizado' },
                { value: 4, text: 'Pediátrico' },
                { value: 5, text: 'Protocolo' },
                { value: 6, text: 'Embarazada' },
            ],
            selectedRegion: '',
            selectedComuna: '',
            regions: [
            "Arica y Parinacota",
            "Tarapacá",
            "Antofagasta",
            "Atacama",
            "Coquimbo",
            "Valparaíso",
            "Libertador Gral. Bernardo O’Higgins",
            "Maule",
            "Ñuble",
            "Biobío",
            "Araucanía",
            "Los Ríos",
            "Los Lagos",
            "Aisén del Gral. Carlos Ibáñez del Campo",
            "Magallanes y de la Antártica Chilena",
            "Metropolitana de Santiago"
            ],
        };
    },
    methods: {
        close() {
            this.alert = !this.alert;
        },
        onRegionChange() {
            this.selectedComuna = '';
        },
        async saveData_Fecha_ingreso_FA() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    Fecha_Ingreso_FA: this.fecha_ingreso_FA 
                };
                try {
                    await this.$store.dispatch("patients/put_fecha_ingreso_FA",payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_Fecha_nacimiento() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    birth_d	: this.Fecha_nacimiento 
                };
                try {
                    await this.$store.dispatch("patients/put_fecha_nacimiento",payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_sexo() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    sex	: this.sexo 
                };
                try {
                    await this.$store.dispatch("patients/put_sexo",payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_Fecha_enrolamiento() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    enrol_d: this.Fecha_enrolamiento 
                };
                try {
                    await this.$store.dispatch("patients/put_fecha_enrolamiento",payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_Fecha_diagnosis() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    diagnosis_d: this.Fecha_diagnosis 
                };
                try {
                    await this.$store.dispatch("patients/put_fecha_diagnosis",payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_mode() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    mode: this.mode 
                };
                try {
                    await this.$store.dispatch("patients/put_mode",payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_mode_oth() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    mode_oth: this.mode_oth 
                };
                try {
                    await this.$store.dispatch("patients/put_mode_oth",payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_art_baseline() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    ART_at_baseline: this.art_baseline 
                };
                try {
                    await this.$store.dispatch("patients/put_art_baseline",payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_cdcstage() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    cdcstage: this.cdcstage 
                };
                try {
                    await this.$store.dispatch("patients/put_cdcstage",payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_aids() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    aids_y: this.aids_y 
                };
                try {
                    await this.$store.dispatch("patients/put_aids",payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_clinicaltrial() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    clinicaltrial_y: this.clinicaltrial 
                };
                try {
                    await this.$store.dispatch("patients/put_clinicaltrial",payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_death_d() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    death_d: this.death_d 
                };
                try {
                    await this.$store.dispatch("patients/put_death_d",payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_drop_d() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    paciente: paciente.rut,
                    drop_d: this.drop_d 
                };
                try {
                    await this.$store.dispatch("patients/post_drop_d",payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_genero() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    Genero: this.genero
                };
                try {
                    await this.$store.dispatch("patients/put_genero",payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_policlinico() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    Policlinico: this.policlinico
                };
                try {
                    await this.$store.dispatch("patients/put_policlinico", payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_inicio() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    inicio_tar: this.inicio_tar
                };
                try {
                    await this.$store.dispatch("patients/put_inicio_tar", payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_condicion_especial() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    condicion_especial: this.condicion_especial
                };
                try {
                    await this.$store.dispatch("patients/put_condicion_especial", payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_hla() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    hla: this.hla
                };
                try {
                    await this.$store.dispatch("patients/put_hla", payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_matrona() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    Matrona: this.Matrona
                };
                try {
                    await this.$store.dispatch("patients/put_matrona", payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_prevision() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    Prevision: this.Prevision
                };
                try {
                    await this.$store.dispatch("patients/put_prevision", payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_geo() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    Region: this.selectedRegion,
                    Comuna: this.selectedComuna
                };
                try {
                    await this.$store.dispatch("patients/put_geo", payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_niveledu() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    nivel_educacional: this.nivel_educacional,
                };
                try {
                    await this.$store.dispatch("patients/put_nivel_edu", payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_educacion() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    educacion: this.educacion,
                };
                try {
                    await this.$store.dispatch("patients/put_edu", payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_empleo_descri() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    employment_description: this.empleo_descri,
                };
                try {
                    await this.$store.dispatch("patients/put_edu", payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_empleo() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    employment_status: this.empleo,
                };
                try {
                    await this.$store.dispatch("patients/put_empleo", payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_hiv_patner() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    hiv_serology_status_partner: this.hiv_partner,
                };
                try {
                    await this.$store.dispatch("patients/put_partner", payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_sex_com() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    sexual_commerce: this.sexual_commerce,
                };
                try {
                    await this.$store.dispatch("patients/put_sexcom", payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_use_condom() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    use_of_condom: this.use_of_condom,
                };
                try {
                    await this.$store.dispatch("patients/put_usecondom", payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_freq_use_condom() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    freq_use_of_condom: this.freq_use_of_condom,
                };
                try {
                    await this.$store.dispatch("patients/put_frequsecondom", payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_adquisicion() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    adquisicion: this.Adquisicion,
                };
                try {
                    await this.$store.dispatch("patients/put_adquisicion", payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_condusexual() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    CONDUSEXUAL: this.Condusexual,
                };
                try {
                    await this.$store.dispatch("patients/put_condusexual", payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_autorizado() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    Tiene_Autorizado: this.autorizado,
                };
                try {
                    await this.$store.dispatch("patients/put_autorizado", payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        async saveData_Fecha_muerte() {
            // Imprime el id del paciente (codigoconasida) desde la ruta
            const pacienteId = this.$route.params["id"];

            // Accede a la lista de pacientes
            const pacientes = this.$store.state.patients.patients;

            // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
            const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

            // Si se encuentra el paciente, lo imprime, de lo contrario imprime un mensaje de error
            if (paciente) {
                const payload = {
                    rut: paciente.rut,
                    nombre: paciente.nombres,
                    apellido_paterno: paciente.primer_apellido,
                    apellido_materno: paciente.segundo_apellido,
                    codigoconasida: paciente.codigoconasida,
                    death_d: this.fecha_muerte 
                };
                try {
                    await this.$store.dispatch("patients/put_fecha_muerte",payload);
                } catch (error) {
                    console.log("Algo falló: ", error);
                }
            } else {
                console.log("No se encontró un paciente con el codigoconasida:", pacienteId);
            }
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        save(date) {
            this.$refs.menu.save(date);
        },
        async predict() {
            const payload = {
                rut: this.patientVariables.rut,
                predictor: "AyD",
            };
            console.log("this values are send to the server", payload);
            this.$store.dispatch("patients/predictAyD", payload).then((res) => {
                console.log("resa", res);
                if (res.status === 400 || res.status === 500) {
                    console.log("res", res);
                    console.log(res.data);
                    this.message_error = res.data.errores;
                    this.alert = true;
                    this.snackbar_color = "error";
                } else {
                    this.$store.dispatch("patients/loadPredictions", this.patientVariables.rut);
                    this.prediction = (res.data[0].probability * 100).toFixed(3);
                    this.message_error = ["Predicción bien hecha"];
                    this.alert = true;
                    this.snackbar_color = "success";
                }
            });
        },
        median(numbers) {
            const sorted = Array.from(numbers).sort((a, b) => a - b);
            const middle = Math.floor(sorted.length / 2);

            if (sorted.length % 2 === 0) {
                return (sorted[middle - 1] + sorted[middle]) / 2;
            }

            return sorted[middle];
        },
        getStandardDeviation(array) {
            const n = array.length;
            const mean = array.reduce((a, b) => a + b) / n;
            return Math.sqrt(array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n);
        },
    },
    computed: {
        comunaRegiones() {
            return this.$store.getters["datosVariables/comunaRegiones"];
        },
        filteredComunas() {
            if (this.selectedRegion) {
                return this.comunaRegiones[this.selectedRegion] || [];
            }
            return [];
        },
        maxYear() {
            return 2000;
        },
        statusForm() {
            return this.$store.getters["patients/statusForm"];
        },
        patientVariables() {
            return this.$store.getters["patients/patientVariables"];
        },
        items_mode_2() {
            return this.$store.getters["datosVariables/items_mode_2"];
        },
        items_mode_oth() {
            return this.$store.getters["datosVariables/items_mode_oth"];
        },
        items_cdc() {
            return this.$store.getters["datosVariables/itemsCDC"];
        },
        items_educacion() {
            return this.$store.getters["datosVariables/items_educacion"];
        },
        items_laboral() {
            return this.$store.getters["datosVariables/items_laboral"];
        },
        items_prevision() {
            return this.$store.getters["datosVariables/items_prevision"];
        },
        comunas() {
            return this.$store.getters["datosVariables/comunas"];
        },
        motivo_choices() {
            return this.$store.getters["datosVariables/motivo_choices"];
        },
        drogas() {
            return this.$store.getters["datosVariables/drogas"];
        },
    },
};
</script>