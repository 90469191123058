<template>
  <section>
    <h1>Modelo Intervencional</h1>
    <div
      v-if="
        (patientVariables.probability_ft && patientVariables.clasificacion_ft) ||
        patientVariables.previous_tar_treatment
      "
    >
      <h3>{{ patientVariables.clasificacion_ft }}</h3>
      <v-divider vertical></v-divider>
      <v-divider></v-divider>
      <v-form @submit.prevent="save_or_edit_data" ref="form">
        <v-radio-group
          v-model="pacienteIntervencional.pregunta_1"
          required
          :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
        >
          <v-subheader>
            <strong> 1.- ¿Se realizó consejería basada en entrevista motivacional con este paciente?</strong>
          </v-subheader>
          <v-radio label="Si" value="SI"></v-radio>
          <v-radio label="No" value="NO"></v-radio>
          <v-radio label="Incompleta" value="Incompleta"></v-radio>
        </v-radio-group>
        <template v-slot:label>
          <strong>2.-¿Se realizó consejería basada en entrevista motivacional con este paciente?</strong>
        </template>
        <v-subheader>
          <strong> 2.- Número de sesiones realizadas:</strong>
        </v-subheader>
        <v-text-field
          v-model="pacienteIntervencional.pregunta_2"
          required
          :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
          label="Número de sesiones"
          hide-details
          single-line
          type="number"
        >
        </v-text-field>

        <v-radio-group
          v-model="pacienteIntervencional.pregunta_3"
          required
          :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
        >
          <v-subheader>
            <strong> 3.- ¿Se logró la meta propuesta en la consejería?</strong>
          </v-subheader>
          <template v-slot:label>
            <strong></strong>
          </template>
          <v-radio label="Si" value="SI"></v-radio>
          <v-radio label="No" value="NO"></v-radio>
          <v-radio label="Parcialmente" value="Parcialmente"></v-radio>
        </v-radio-group>
        <v-row>
          <v-col>
            <v-subheader>
              <strong> 4.- ¿Derivado a otro profesional?</strong>
            </v-subheader>
            <v-radio-group
              v-model="pacienteIntervencional.pregunta_4a"
              required
              :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
            >
              <v-radio label="Si" value="SI"></v-radio>
              <v-radio label="No" value="NO"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col>
            <v-text-field
              v-if="pacienteIntervencional.pregunta_4a === 'SI'"
              v-model="pacienteIntervencional.pregunta_4b"
              required
              :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
              label="Profesional derivado?"
              hide-details
              single-line
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field label="Comentarios" v-model="pacienteIntervencional.comentarios"></v-text-field>
          </v-col>
        </v-row>
        <!-- {{ pacienteIntervencional }} -->
        <v-alert v-if="alert === 'exito'" type="success" @click="alert = null"> Datos Guardados! </v-alert>
        <v-btn
          :exact="true"
          :to="{
            name: 'pacienteFastTrackPrediction',
            params: { id: this.$route.params['id'] },
          }"
        >
          Atras
        </v-btn>
        <v-btn v-if="!hayDatos" class="normal" type="submit">Guardar</v-btn>
        <v-btn v-else class="normal" type="submit">Editar</v-btn>
      </v-form>
    </div>
    <v-snackbar v-model="dialog" :color="snackbar_color">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="dialog = false"> Cerrar </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      alert: false,
      pacienteIntervencional: null,
      hayDatos: false,
      message: null,
      snackbar_color: null,
    };
  },
  methods: {
    save_or_edit_data() {
      //Agregar
      if (!this.hayDatos) {
        this.$store
          .dispatch("patients/addIntervencional", {
            ...this.pacienteIntervencional,
            paciente: this.$store.getters["patients/patient"].data.rut,
          })
          .then((response) => {
            //Todo bien
            if (response.status !== 400 || response.status === 201) {
              this.hayDatos = true;
              this.dialog = true;
              this.message = "Datos subido adecuadamente";
              this.snackbar_color = "success";
              this.pacienteIntervencional.id = response.data.id;
              //Error
            } else if (response.status === 400) {
              this.dialog = true;
              this.message = response.data;
              this.snackbar_color = "error";
            }
          });

        //EDITAR
      } else {
        this.$store.dispatch("patients/editIntervencional", this.pacienteIntervencional).then((result) => {
          if (result.status !== 400) {
            this.dialog = true;
            this.message = "Datos subido adecuadamente";
            this.snackbar_color = "success";
          }
        });
      }
    },
    /*     Guardar_Datos() {
      const payload = {
        ...this.pacienteIntervencional,
        paciente: this.$store.getters["patients/patient"].data.rut,
      };
      if (this.pacienteIntervencional.id || this.pacienteIntervencional.paciente) {
        this.$store.dispatch("patients/editIntervencional", payload);
      } else {
        this.$store.dispatch("patients/addIntervencional", payload);
      }
      this.dialog = true;
      this.$store.commit("patients/setPacienteIntervencional", payload);
    }, */
  },
  computed: {
    patientVariables() {
      return this.$store.getters["patients/patientVariables"];
    },
  },
  created() {
    if (this.$store.getters["patients/pacienteIntervencional"].id) {
      this.hayDatos = true;
    }
    this.pacienteIntervencional = this.$store.getters["patients/pacienteIntervencional"];
  },
};
</script>
