<template>
  <v-card>
    <v-card-title>Fechas Importantes</v-card-title>
    <v-card-text>
      <v-list>
        <v-list-item v-for="(fecha, index) in fechasImportantes" :key="index">
          <v-list-item-content>
            <v-list-item-title class="text-h6 mb-2">{{
              fecha.nombre
            }}</v-list-item-title>
            <v-row align="center" justify="space-between">
              <v-col cols="12" sm="5" md="4">
                <v-menu
                  v-model="fecha.menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fecha.valor"
                      :label="`Fecha ${fecha.nombre}`"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fecha.valor"
                    @input="fecha.menu = false"
                    color="primary"
                  />
                </v-menu>
              </v-col>
              <v-col cols="12" sm="7" md="8" class="d-flex justify-end">
                <v-btn
                  color="primary"
                  class="mr-2"
                  @click="guardarFecha(index)"
                >
                  Guardar
                </v-btn>
                <v-btn color="secondary" @click="verHistorial(index)">
                  Ver Historial
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ListaFechasImportantes",
  data() {
    return {
      fechasImportantes: [
        { nombre: "Último Control", valor: null, menu: false, type: null },
        {
          nombre: "Receta Patologías Oportunistas",
          valor: null,
          menu: false,
          type: 1,
        },
        {
          nombre: "Receta Terapia Antiretroviral",
          valor: null,
          menu: false,
          type: 0,
        },
      ],
    };
  },
  methods: {
    async guardarFecha(index) {
      const fecha = this.fechasImportantes[index];
      try {
        await this.$store.dispatch("recetas/addReceta", {
          type: fecha.type,
          date: fecha.valor,
        });
        this.$store.dispatch("toast/showToast", {
          message: `Fecha de ${fecha.nombre} guardada con éxito`,
          tipo: "success",
        });
      } catch (error) {
        console.error("Error al guardar la fecha", error);
        this.$store.dispatch("toast/showToast", {
          message: `Error al guardar la fecha de ${fecha.nombre}`,
          tipo: "error",
        });
      }
    },
    verHistorial(index) {
      const fecha = this.fechasImportantes[index];
      this.$router.push({
        name: "HistorialFechas",
        params: { tipo: fecha.nombre },
      });
    },
  },
  async created() {
    try {
      const fechas = await this.$store.dispatch("recetas/fetchRecetas");
      this.fechasImportantes.forEach((fecha) => {
        fecha.valor = fechas[fecha.nombre] || null;
      });
    } catch (error) {
      console.error("Error al cargar las fechas", error);
    }
  },
};
</script>
