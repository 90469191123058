<template>
  <v-main>
    <h2>Datos Personales {{ id }}</h2>
    <v-form @submit.prevent="sendData">
      <!-- Estado serológico de VIH de la pareja -->
      <v-subheader>
        <strong>Estado serológico de VIH de la pareja</strong>
      </v-subheader>
      <v-select
        label="Seleccione estado serológico de VIH de la pareja"
        v-model="patient.hiv_serology_status_partner"
        :items="status_serology"
        item-text="label"
        item-value="value"
        no-data-text="No hay opciones disponibles aún"
      />
      <!--------------------->
      <!-- Comercio sexual -->
      <strong>Comercio sexual</strong>
      <v-radio-group v-model="patient.sexual_commerce">
        <v-radio
          v-for="option in normal_choices"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        ></v-radio>
      </v-radio-group>
      <!------------------->
      <!-- Uso de condon -->
      <strong>Uso de condon</strong>
      <v-radio-group v-model="patient.use_of_condom">
        <v-radio
          v-for="option in normal_choices"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        ></v-radio>
      </v-radio-group>
      <!------------------------------>
      <!-- Frecuencia uso de condon -->
      <v-subheader>
        <strong>Frecuencia uso de condon</strong>
      </v-subheader>
      <v-select
        label="Seleccione frecuencia uso de condon"
        v-model="patient.freq_use_of_condom"
        :items="frecuency_of_condom"
        item-text="label"
        item-value="value"
        no-data-text="No hay opciones disponibles aún"
      />
      <!------------------------------>
      <!-- Condición Especial       -->
      <v-subheader>
        <strong>Condición especial del paciente</strong>
      </v-subheader>
      <v-select
        label="Condición especial del paciente"
        v-model="patient.condicion_especial"
        :items="choices_condicion_especial"
        item-text="label"
        item-value="value"
        no-data-text="No hay opciones disponibles aún"
      />
      <!------------------------------>
      <!-- Adquisición              -->
      <v-subheader>
        <strong>Adquisición de la enfermedad</strong>
      </v-subheader>
      <v-select
        label="Adquisición de la enfermedad"
        v-model="patient.adquisicion"
        :items="choices_adquisicion"
        item-text="label"
        item-value="value"
        no-data-text="No hay opciones disponibles aún"
      />
      <!------------------------------>
      <!-- Situación                -->
      <v-subheader>
        <strong>Situación usuario</strong>
      </v-subheader>
      <v-select
        label="Situación usuario"
        v-model="patient.situation"
        :items="situation_choices"
        item-text="label"
        item-value="value"
        no-data-text="No hay opciones disponibles aún"
      />
      <v-btn type="submit">Guardar datos</v-btn>
    </v-form>
    <MonitoreoFechas />
  </v-main>
</template>

<script>
import MonitoreoFechas from "@/components/MonitoreoFechas.vue";
export default {
  components: { MonitoreoFechas },
  data() {
    return {
      patient: {},
      status_serology: [
        { label: "Sin datos", value: 0 },
        { label: "Pareja VIH (+)", value: 1 },
        { label: "Pareja VIH (-)", value: 2 },
        { label: "Sin pareja", value: 3 },
        { label: "Multiples parejas", value: 4 },
      ],
      normal_choices: [
        { label: "Sin datos", value: 0 },
        { label: "Sí", value: 1 },
        { label: "No", value: 2 },
      ],
      frecuency_of_condom: [
        { label: "Sin datos", value: 0 },
        { label: "Nunca", value: 1 },
        { label: "Rara vez", value: 2 },
        { label: "Mayoria de las veces", value: 3 },
        { label: "Siempre", value: 4 },
      ],
      choices_condicion_especial: [
        { label: "No", value: 0 },
        { label: "Dipreca", value: 1 },
        { label: "PPL", value: 2 },
        { label: "Hospitalizado", value: 3 },
        { label: "Pediátrico", value: 4 },
        { label: "Protocolo", value: 5 },
        { label: "Embarazada", value: 6 },
      ],
      choices_adquisicion: [
        { label: "Sin datos", value: 0 },
        { label: "Transmisión Vertical", value: 1 },
        { label: "Transmisión Sexual", value: 2 },
        { label: "No Sabe/No Responde", value: 3 },
        { label: "IVDU", value: 4 },
        { label: "Ocupacional", value: 5 },
      ],
      situation_choices: [
        { value: 0, label: "Activo" },
        { value: 1, label: "Traslado" },
        { value: 2, label: "Abandono" },
        { value: 3, label: "Fallecido" },
        { value: 4, label: "Falso(+)" },
        { value: 5, label: "Paciente Otro Centro" },
        { value: 6, label: "NoVIH" },
      ],
    };
  },
  props: ["id"],
  methods: {
    sendData() {
      this.$store.dispatch("patients/addDatosPersonales", this.patient);
    },
    async loadDatosPersonales() {
      await this.$store.dispatch("patients/loadDatosPersonales", {
        codigoconasida: this.id,
      });
    },
  },
  created() {
    this.loadDatosPersonales().then(
      () => (this.patient = this.$store.getters["patients/getPatient"])
    );
  },
};
</script>
