<template>
    <button :class="mode"><slot></slot></button>
</template>
<script>
export default {
    props:['mode']
}
</script>
<style scoped>
button{
    background-color: #504ca3;
    padding: 0.3rem 0.75rem;
    font-family: inherit;
    font-weight: bolder;
    border: 1px solid #504ca3;
    cursor: pointer;
    border-radius: 6px;
    margin: 0.1rem;
    color: rgb(245, 245, 245);
}
button:hover,
button:active{
    background-color: #05006c;
    color: white;
    border-color: #05006c
}
.flat {
    background-color:transparent;
    color: #3a0061;
    border:none;
}
</style>