const state = {
    isVisible: false,
    title: '',
    message: '',
    onConfirm: null,
};
const getters = {
    isVisible: state => state.isVisible,
    dialogData: state => ({
        title: state.title,
        message: state.message,
        onConfirm: state.onConfirm,
    })
}

const actions = {
    showDialog({ commit }, { title, message, onConfirm }) {
        commit('showDialog', { title, message, onConfirm });
    },
    hideDialog({ commit }) {
        commit('hideDialog');
    },
}
const mutations = {
    showDialog(state, { title, message, onConfirm }) {
        state.isVisible = true;
        state.title = title;
        state.message = message;
        state.onConfirm = onConfirm;
    },
    hideDialog(state) {
        state.isVisible = false;
        state.title = '';
        state.message = '';
        state.onConfirm = null;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}