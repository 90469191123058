var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[[_c('v-card',{staticClass:"mx-auto",attrs:{"tile":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('h1',[_vm._v("Paciente "+_vm._s(_vm.id))])])],1)],1),_c('v-col',{staticClass:"lesspadding"},[_c('h3',[(!_vm.showFastTrack)?_c('v-icon',{on:{"click":function($event){return _vm.clickShow('fasttrack')}}},[_vm._v("mdi-eye")]):_c('v-icon',{on:{"click":function($event){return _vm.clickShow('fasttrack')}}},[_vm._v("mdi-eye-off")]),_c('router-link',{attrs:{"to":{
              name: 'pacienteFastTrack',
              params: { id: this.id },
            }}},[_vm._v(" Predicción Fast Track:")])],1),(_vm.showFastTrack)?_c('v-list-item-subtitle',[_c('v-row',[_c('v-col',[_c('h3',[_vm._v("Intervención:")]),(_vm.patientVariables.clasificacion_ft)?_c('p',[_vm._v(_vm._s(_vm.patientVariables.clasificacion_ft))]):_c('p',[_vm._v("No hay predicción aún")])])],1)],1):_vm._e(),_c('h3',[(!_vm.showFarmacia)?_c('v-icon',{on:{"click":function($event){return _vm.clickShow('farmacia')}}},[_vm._v("mdi-eye")]):_c('v-icon',{on:{"click":function($event){return _vm.clickShow('farmacia')}}},[_vm._v("mdi-eye-off")]),_c('router-link',{attrs:{"to":{
              name: 'Farmacia',
              params: { id: this.id },
            }}},[_vm._v(" Prediccion Farmacia:")])],1),_c('v-list-item-subtitle',[(_vm.showFarmacia)?_c('ul',_vm._l((_vm.predictionFarmaciaData),function(i){return _c('li',{key:i.year},[_vm._v(" "+_vm._s(i.year)+" - "+_vm._s(Object.values(i.label)[0])+" - ("+_vm._s(Object.keys(i.label)[0])+") - "+_vm._s(i.probabilidad.toFixed(2)))])}),0):_vm._e()]),_c('h3',[(!_vm.showEcosistema)?_c('v-icon',{on:{"click":function($event){return _vm.clickShow('ecosistema')}}},[_vm._v("mdi-eye")]):_c('v-icon',{on:{"click":function($event){return _vm.clickShow('ecosistema')}}},[_vm._v("mdi-eye-off")]),_c('router-link',{attrs:{"to":{
              name: 'Ecosistema',
              params: { id: this.id },
            }}},[_vm._v(" Prediccion Ecosistema:")])],1),_c('v-list-item-subtitle',[(_vm.showEcosistema)?_c('ul',_vm._l((_vm.predictionEcosistemaData),function(i){return _c('li',{key:i.year},[_vm._v(" "+_vm._s(i.year)+" - "+_vm._s(Object.values(i.label)[0])+" - ("+_vm._s(Object.keys(i.label)[0])+") - "+_vm._s(i.probabilidad.toFixed(2)))])}),0):_vm._e()]),_c('h3',[(!_vm.showDepresion)?_c('v-icon',{on:{"click":function($event){return _vm.clickShow('depresion')}}},[_vm._v("mdi-eye")]):_c('v-icon',{on:{"click":function($event){return _vm.clickShow('depresion')}}},[_vm._v("mdi-eye-off")]),_c('router-link',{attrs:{"to":{
              name: 'Depresion',
              params: { id: this.id },
            }}},[_vm._v(" Prediccion Depresion:")])],1),_c('v-list-item-subtitle',[(_vm.showDepresion)?_c('ul',_vm._l((_vm.predictionDepresiónData),function(i){return _c('li',{key:i.year},[_vm._v(" "+_vm._s(i.year)+" - "+_vm._s(Object.values(i.label)[0])+" - ("+_vm._s(Object.keys(i.label)[0])+") - "+_vm._s(i.probabilidad.toFixed(2)))])}),0):_vm._e()]),_c('h3',[(!_vm.showAyD)?_c('v-icon',{on:{"click":function($event){return _vm.clickShow('ayd')}}},[_vm._v("mdi-eye")]):_c('v-icon',{on:{"click":function($event){return _vm.clickShow('ayd')}}},[_vm._v("mdi-eye-off")]),_c('router-link',{attrs:{"to":{
              name: 'Alcohol',
              params: { id: this.id },
            }}},[_vm._v(" Prediccion Alcohol y otros Drogas:")])],1),_c('v-list-item-subtitle',[(_vm.showAyD)?_c('ul',_vm._l((_vm.predictionAyDData),function(i){return _c('li',{key:i.year},[_vm._v(" "+_vm._s(i.year)+" - "+_vm._s(Object.values(i.label)[0])+" - ("+_vm._s(Object.keys(i.label)[0])+") - "+_vm._s(i.probabilidad.toFixed(2)))])}),0):_vm._e()])],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }