<template>
  <v-app>
    <v-container class="container-safe-area">
      <v-container>
        <v-col cols="12" sm="12" xs="12">
          <v-card :flat="true">
            <v-row class="align-center">
              <v-col
                v-for="(item, i) in items"
                cols="4"
                :key="i"
                align-content="center"
                no-gutters
                dense
              >
                <v-img
                  class="img-fluid"
                  :src="item.src"
                  max-height="150"
                  max-width="150"
                  
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-container>
    </v-container>
  </v-app>
</template>
<script>
export default {
  data() {
    return {
      items: [
        { src: require("../assets/images/logo_FA.png") },
        { src: require("../assets/images/wic.png") },
        { src: require("../assets/images/logoISCI.png") },
        { src: require("../assets/images/uchile.png") },
        { src: require("../assets/images/fcfm.png") },
        { src: require("../assets/images/medicina.png") },
        { src: require("../assets/images/anid_logo.png") },
      ],
    };
  },
};
</script>

<style scoped>
.align-center {
  justify-content: center;
  display:flex;
}
</style>
