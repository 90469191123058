<template>
  <v-app>
    <v-container class="institutions container-safe-area">
      <v-col class="columna_institutions">
        <v-row justify="center"
          ><v-img
            class="logoVIDA"
            :src="require('../assets/images/logoVidaCut.png')"
            max-height="350"
            max-width="350"
          ></v-img
        ></v-row>
        <v-row justify="center"><h1 class="titulo">VIDA</h1></v-row>
        <v-row justify="center"
          ><v-card :flat="true"
            ><v-card-title><b> ¿Cómo desea ingresar?</b> </v-card-title>
            <v-card-subtitle>
              Escoge tu entidad/organización</v-card-subtitle
            ></v-card
          ></v-row
        >
        <v-row justify="center">
          <v-btn-toggle v-model="toggle_institucion"  mandatory>
            <v-btn value="Fundaciones" >Fundaciones</v-btn>
            <v-btn value="Hospitales" disabled>Hospitales</v-btn>
            <v-btn value="Clinicas" disabled>Clinicas</v-btn>
            <v-btn value="Institutos" disabled>Institutos</v-btn>
            <v-btn value="Otros" disabled>Otros</v-btn>
          </v-btn-toggle>
        </v-row>
        <v-row justify="center"
          ><router-link :to="{ name: 'login',params:{institucion:this.toggle_institucion} }"
            ><v-btn class="boton_ingreso"> Siguiente</v-btn></router-link
          ></v-row
        >
      </v-col>
    </v-container>
  </v-app>
</template>
<script>
export default {
  data(){
    return{
      toggle_institucion:null
    }
  }
}
</script>
<style scoped>
.boton_ingreso {
  padding: 10px;
  margin: 15px;
}
.titulo{
  font-size: 60px;
  margin-top:0px;
  padding-top: 0px;
}
.logoVIDA {
  padding: 0px;
  margin: 0px;
}
.institutions {
  padding: 0px;
  padding-top: 0px;
  margin-top: 0px;
}
.columna_institutions {
  padding: 0px;
  padding-top: 0px;
  margin-top: 0px;
}
</style>
