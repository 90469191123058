<template>
  <v-container>
    <h2>Encuesta</h2>
    <p class="sub-header-poll">
      A continuación las siguientes preguntas sobre el diagnóstico del paciente
    </p>
    <v-form ref="form" @submit.prevent="siguiente" v-model="valid">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            label="Fecha encuesta"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-model="date"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="date"
          :max="
            new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substring(0, 10)
          "
          min="1900-01-01"
        />
      </v-menu>
      <p class="pregunta-poll">
        1. ¿Tienes actualmente alguna de las siguientes condiciones crónicas
        (diagnosticadas)?
        <span class="sub-pregunta-poll">
          (Marca todas las que correspondan)
        </span>
      </p>

      <v-checkbox
        v-for="n in this.ENFERMEDADES"
        :key="n"
        :label="`${n}`"
        :value="n"
        color="#1dc6d8"
        v-model="encuesta_enfermedad"
        required
        :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
      />
      <p class="pregunta-poll">
        2. En el caso de presentar otra enfermedad indique cual? (antes de
        comenzar el tratamiento)
      </p>
      <v-text-field
        v-model="otra_enfermedad"
        label="Escriba su respuesta"
        outlined
        :rules="[
          (v) =>
            /^[a-zA-ZÀ-ÿ\s]+$/.test(v) ||
            'Solo se permiten letras, espacios y tildes',
        ]"
      />
      <v-row justify="end">
        <v-col cols="auto">
          <v-btn type="submit" class="golden-button" width="xl">Guardar</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "InfoPacienteEncuesta",
  data() {
    return {
      date: new Date().toISOString().substring(0, 10),
      menu: false,
      valid: false,
      encuesta_enfermedad: [],
      otra_enfermedad: "",
      ENFERMEDADES: [
        "Hipertensión arterial",
        "Diabetes",
        "Enfermedad coronaria (por ejemplo: infarto, angina)",
        "Cancer activo",
        "Cancer pasado/recuperado",
        "Enfermedad pulmonar crónica (Por ejemplo: asma, EPOC, fibrosis)",
        "Hepatitis B",
        "Hepatitis C",
        "Enfermedad renal crónica",
        "Enfermedades psiquiátricas",
      ],
    };
  },
  methods: {
    async siguiente() {
      this.$refs.form.validate();
      const { valid, encuesta_enfermedad, otra_enfermedad, date } = this;
      if (valid) {
        const pacienteId = this.$route.params["id"];
        // Accede a la lista de pacientes
        const pacientes = this.$store.state.patients.patients;

        // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
        const paciente = pacientes.find((p) => p.codigoconasida === pacienteId);

        const rut = paciente.rut;

        const encuesta_enfermedad_json = encuesta_enfermedad.reduce(
          (acc, item, index) => {
            acc[index + 1] = item; // Asigna cada elemento con un ID numérico, comenzando en 1
            return acc;
          },
          {}
        );
        const payload = {
          paciente_nombre: rut,
          otra_enfermedad: otra_enfermedad,
          Encuesta_1: encuesta_enfermedad_json,
          register_poll_date: date,
          method: "POST",
        };
        try {
          await this.$store.dispatch("patients/addEncuestaInfo", payload);
        } catch (error) {
          console.log("Algo falló: ", error);
        }
      } else {
        console.log("Formulario invalido");
      }
    },
  },
};
</script>

<style>
.golden-button {
  color: white !important;
  background-color: #f4a261 !important;
  width: 190px;
  border-radius: 10px;
}
.sub-header-poll {
  color: #787878;
  font-weight: 400;
}
.pregunta-poll {
  font-size: 18px;
  font-weight: 500;
  color: #494949;
  line-height: 1.2;
}
.sub-pregunta-poll {
  font-style: italic;
  color: #787878;
  font-size: 16px;
  font-weight: 400;
}
.sub-pregunta-poll::before {
  content: "\A";
  white-space: pre;
}
.v-radio .v-icon {
  color: #1dc6d8;
}
</style>
