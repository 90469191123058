<template>
  <v-container>
    <v-dialog
      v-model="dialogAdd"
      @click:outside="close()"
      content-class="elevation-0"
      max-width="800px"
    >
      <AddTAR @close-dialog="dialogAdd = false" />
    </v-dialog>
    <v-dialog
      v-model="dialogEdit"
      @click:outside="close()"
      content-class="elevation-0"
      max-width="800px"
    >
      <DetailTAR :idTAR="this.idTarDetail" @close-dialog="dialogEdit = false" />
    </v-dialog>
    <v-row align="center" justify="space-between" class="mb-4">
      <v-col cols="auto">
        <h1>Lista de tratamientos antiretrovirales</h1>
      </v-col>
      <v-col cols="auto">
        <v-btn color="primary" @click="crearNuevaTerapia">
          <v-icon left>mdi-plus</v-icon>
          Crear Nuevo tratamiento
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="terapiasConTexto"
      :items-per-page="5"
      no-data-text="No hay tratamientos disponibles"
      :footer-props="{ 'items-per-page-text': 'Registros por página' }"
    >
      <template #item="{ item }">
        <tr>
          <td>{{ item.id }}</td>
          <td>{{ item.fecha_accion }}</td>
          <td>{{ item.fecha_fin }}</td>
          <td>{{ item.accionText }}</td>
          <td>{{ item.motivoText }}</td>
          <td>
            <v-btn small color="primary" @click="editarTerapia(item.id)">
              Editar
            </v-btn>
            <v-btn
              small
              color="error"
              @click="handlerEliminarTerapiaPac(item.id)"
            >
              Eliminar
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import AddTAR from "./AddTAR.vue";
import DetailTAR from "./DetailTAR.vue";
export default {
  components: {
    AddTAR,
    DetailTAR,
  },
  data() {
    return {
      idTarDetail: null,
      dialogAdd: false,
      dialogEdit: false,
      headers: [
        { text: "Identificación Terapia", value: "id" },
        { text: "Fecha Accion", value: "fecha_accion" },
        { text: "Fecha Fin", value: "fecha_fin" },
        { text: "Accion", value: "accionText" },
        { text: "Motivo", value: "motivoText" },
        { text: "Acciones", value: "acciones", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState("terapias", ["terapias"]),
    ...mapGetters("terapias", ["getAccionText", "getMotivoText"]),
    terapiasConTexto() {
      return this.terapias.map((terapia) => ({
        ...terapia,
        accionText: this.getAccionText(terapia.accion),
        motivoText: this.getMotivoText(terapia.motivo),
      }));
    },
    pacienteConasida() {
      return this.$route.params.id;
    },
  },
  methods: {
    ...mapActions("terapias", ["fetchTerapias", "eliminarTerapiaPac"]),
    editarTerapia(id) {
      this.idTarDetail = id;

      this.dialogEdit = true;
      /* this.$router.push({ name: "detailTAR", params: { idTar: id } });
       */
    },
    crearNuevaTerapia() {
      this.dialogAdd = true;
    },
    close() {
      this.dialogAdd = false;
    },
    handlerEliminarTerapiaPac(id) {
      console.log("asd");

      this.$store.dispatch("dialog/showDialog", {
        title: "Eliminar Terapia",
        message: "¿Está seguro que desea eliminar la terapia?",
        onConfirm: () => {
          this.eliminarTerapiaPac(id);
        },
      });
    },
  },
  created() {
    this.fetchTerapias(this.pacienteConasida);
  },
};
</script>
<style>
.border-2 {
  border: 2px solid #000;
}
</style>
