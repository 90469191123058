<template>
  <section
    class="d-flex flex-column align-center justify-center custom-height"
    v-if="this.isLoading"
  >
    <v-progress-circular indeterminate :size="50" color="primary" />
    <h3>Cargando...</h3>
  </section>
  <section v-else>
    <v-data-table
      v-if="desserts"
      :headers="headers"
      :items="desserts"
      no-data-text="No hay mediciones disponibles"
      :footer-props="{ 'items-per-page-text': 'Registros por página' }"
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title class="custom-toolbar-title">
            Mediciones de CD4
          </v-toolbar-title>
          <v-spacer />
          <v-dialog v-model="dialog" max-width="600px" @click:outside="close()">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Nueva Medición CD4
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            required
                            :rules="[(v) => !!v || 'Falta fecha de nacimiento']"
                            v-model="editedItem.cd4_d"
                            label="Fecha de registro"
                            prepend-icon="mdi-calendar"
                            readonly
                            no-title
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="editedItem.cd4_d"
                          v.model:active-picker="activePicker"
                          :max="
                            new Date(
                              Date.now() -
                                new Date().getTimezoneOffset() * 60000
                            )
                              .toISOString()
                              .substr(0, 10)
                          "
                          min="1950-01-01"
                          color="primary"
                        >
                        </v-date-picker
                      ></v-menu>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="editedItem.cd4_v"
                        label="Medida CD4"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="close">
                  Cancelar
                </v-btn>
                <v-btn
                  :disabled="!isFormValid"
                  color="blue darken-1"
                  text
                  @click="save('cd4')"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="600px">
            <v-card>
              <v-card-title class="text-h7">
                Estas seguro que quieres eliminar esta medición?
              </v-card-title>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                  SI
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      desserts: null,
      menu: false,
      headers: [
        { text: "Fecha", value: "cd4_d" },
        { text: "Medición CD4", value: "cd4_v" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      dialog: false,
      editedItem: {
        cd4_d: null,
        cd4_v: null,
        id: null,
      },
      dialogDelete: false,
    };
  },
  methods: {
    async loadCD4Data() {
      await this.$store.dispatch("patients/loadCD4Data", {
        idPatient: this.patientVariables.rut,
      });
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    save() {
      if (this.editedIndex > -1) {
        this.$store.dispatch("patients/editCD4", {
          ...this.editedItem,
          paciente: this.patientVariables.rut,
        });
        Object.assign(this.desserts[this.editedIndex], {
          ...this.editedItem,
          paciente: this.patientVariables.rut,
        });
      } else {
        this.$store.dispatch("patients/addCD4", {
          ...this.editedItem,
          paciente: this.patientVariables.rut,
        });
        this.desserts.push(this.editedItem);
      }

      this.close();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async deleteItem(item) {
      await this.$store.dispatch("patients/deleteCD4", item.id);

      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
      await this.loadCD4Data();
    },
    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
  computed: {
    ...mapGetters("patients", ["isLoading"]),
    formTitle() {
      return this.editedIndex === -1 ? "Nueva medición" : "Editar medición";
    },
    isFormValid() {
      return this.editedItem.cd4_d && this.editedItem.cd4_v;
    },
    patientVariables() {
      return this.$store.getters["patients/patientVariables"];
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  async mounted() {
    await this.loadCD4Data().then(() => {
      this.desserts = this.$store.getters["patients/patientCD4"];
    });
  },
};
</script>
<style>
.custom-toolbar-title {
  color: #787878;
  font-weight: 700;
}
</style>
