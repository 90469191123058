<template>
  <div class="footer">
    <span class="made"
      >Creado por
      <a href="https://wic.uchile.cl/">Web Intelligence Centre</a></span
    >
    <span class="year">VIDA {{ year }}</span>
    <span
      v-if="title"
      style="background-color: yellow; color: black; font-weight: bold"
      >Corriendo en servidor de desarrollo</span
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style scoped>
.footer {
  background-color: rgb(29, 196, 216);
  color: white;
  padding: 10px;
  margin: 0px;
  height: 3rem;
}
.made {
  color: white;
}
a {
  text-decoration: None;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  color: white;
  font-weight: bold;
}
.year {
  margin: 0px;
  color: white;
  font-weight: bold;
  margin-left: 0px;
  float: right;
}
</style>
