var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-container',{staticClass:"container-safe-area"},[_c('v-tabs',{attrs:{"fixed-tabs":"","background-color":"indigo","color":"white"},model:{value:(_vm.modelTab),callback:function ($$v) {_vm.modelTab=$$v},expression:"modelTab"}},[_c('v-tab',{attrs:{"exact":true,"to":{
          name: 'Farmacia',
          params: { id: this.$route.params['id'] },
        }}},[_vm._v(" Farmacia ")]),_c('v-tab',{attrs:{"exact":true,"to":{
          name: 'Ecosistema',
          params: { id: this.$route.params['id'] },
        }}},[_vm._v(" Ecosistema ")]),_c('v-tab',{attrs:{"exact":true,"to":{
          name: 'Depresion',
          params: { id: this.$route.params['id'] },
        }}},[_vm._v(" Depresión ")]),_c('v-tab',{attrs:{"exact":true,"to":{
          name: 'Alcohol',
          params: { id: this.$route.params['id'] },
        }}},[_vm._v(" Alcohol y otras drogas ")]),_c('v-tab',{attrs:{"exact":true,"to":{
          name: 'Extras',
          params: { id: this.$route.params['id'] },
        }}},[_vm._v(" Extras ")])],1),_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }