import Vuex from "vuex";
import Vue from "vue";
import * as Cookies from "js-cookie";
Vue.use(Vuex);

/* import auth from  '../modules/auth/store' */
import authModule from "./auth/index.js";
import patientsModule from "./patients/index.js";
import datosVariables from "./datosVariables/index.js";
import createPersistedState from "vuex-persistedstate";
import terapias from "./terapias.js";
import toast from "./toast.js";
import recetas from "./recetas.js";
import dialog from "./dialog.js";
const store = new Vuex.Store({
  modules: {
    auth: authModule,
    patients: patientsModule,
    datosVariables: datosVariables,
    terapias: terapias,
    toast: toast,
    recetas: recetas,
    dialog: dialog,
  },
  plugins: [
    createPersistedState({
      paths: ['paciente'],
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, { expires: 3, secure: true }),
        removeItem: (key) => Cookies.remove(key),
      },
    }),
  ],
});

export default store;
