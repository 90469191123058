<template>
  <v-container>
    <v-card>
      <v-card-title>Agregar Entrega</v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="isFormValid"
          @submit.prevent="guardarRetiro"
        >
          <v-text-field
            v-model.number="nuevoRetiro.n_receta"
            label="Nº Receta"
            type="number"
            :rules="[(v) => v > 0 || 'Nº Receta debe ser mayor que 0']"
            required
          />
          <v-select
            v-model="nuevoRetiro.terapia"
            :items="terapiaOptions"
            label="Selecciona la terapia asociada"
            item-value="id"
            item-text="id"
            :rules="[(v) => !!v || 'Debe seleccionar una terapia']"
            required
          />
          <v-text-field
            v-model="nuevoRetiro.frp_tar"
            label="Fecha receta TAR"
            type="date"
            :rules="[(v) => !!v || 'Fecha de entrega es requerida']"
            required
          />
          <v-text-field
            v-model="nuevoRetiro.frp_op"
            label="Fecha receta oportunista"
            type="date"
            :rules="[(v) => !!v || 'Fecha de retiro es requerida']"
            required
          />
          <v-text-field
            v-model="nuevoRetiro.frp_hosp"
            label="Fecha receta hospital"
            type="date"
            :rules="[(v) => !!v || 'Fecha de retiro es requerida']"
            required
          />
          <v-text-field
            v-model="nuevoRetiro.fecha_entrega"
            label="Fecha Entrega"
            type="date"
            :rules="[(v) => !!v || 'Fecha de entrega es requerida']"
            required
          />
          <v-text-field
            v-model="nuevoRetiro.fecha_retiro"
            label="Fecha Retiro"
            type="date"
            :rules="[(v) => !!v || 'Fecha de retiro es requerida']"
            required
          />
          <v-checkbox
            v-model="nuevoRetiro.Rp_hospitalizado"
            label="Rp_hospitalizado"
            :true-value="true"
            :false-value="false"
          />
          <v-checkbox
            v-model="nuevoRetiro.Retira_tutor"
            label="¿Retira Tutor?"
            :true-value="true"
            :false-value="false"
          />
          <v-checkbox
            v-model="nuevoRetiro.fraccionado"
            label="¿Fraccionado?"
            :true-value="true"
            :false-value="false"
          />
          <v-text-field
            v-model="nuevoRetiro.fecha_situacion"
            label="Fecha Situación"
            type="date"
            :rules="[(v) => !!v || 'Fecha situación es requerida']"
            required
          />
          <v-select
            v-model="nuevoRetiro.situacion"
            :items="situacionOptions"
            label="Situacion"
            :rules="[(v) => !!v || 'Debe seleccionar una situación']"
            required
          />
          <v-select
            v-model="nuevoRetiro.type"
            :items="typeOptions"
            label="Type"
            :rules="[(v) => !!v || 'Debe seleccionar un type']"
            required
          />
          <v-btn
            type="submit"
            color="primary"
            class="mr-4"
            :disabled="!isFormValid || isSaving"
          >
            {{ isSaving ? "Guardando..." : "Guardar Entrega" }}
          </v-btn>
          <v-btn @click="cancelar" color="error"> Cancelar </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "AddEntrega",
  data() {
    return {
      isFormValid: false,
      isSaving: false,
      nuevoRetiro: {
        n_receta: "",
        terapia: null,
        frp_tar: "",
        frp_op: "",
        frp_hosp: "",
        fecha_entrega: "",
        fecha_retiro: "",
        Retira_tutor: false,
        fraccionado: false,
        Rp_hospitalizado: false,
        fecha_situacion: "",
        situacion: "",
        type: "",
      },
      situacionOptions: ["Activo", "Abandono"],
      typeOptions: ["ADE", "NADE", "CANCER", "OTHER"],
    };
  },
  computed: {
    ...mapState("patients", ["patient"]),
    ...mapGetters("terapias", ["terapiaIds"]),
    terapiaOptions() {
      return this.terapiaIds.map((id) => ({ id }));
    },
  },
  methods: {
    ...mapActions("patients", ["getPatient"]),
    async guardarRetiro() {
      if (this.$refs.form.validate()) {
        const datosReales = {
          n_receta: this.nuevoRetiro.n_receta,
          terapia: this.nuevoRetiro.terapia,
          frp_tar: this.nuevoRetiro.frp_tar,
          frp_op: this.nuevoRetiro.frp_op,
          frp_hosp: this.nuevoRetiro.frp_hosp,
          fecha_entrega: this.nuevoRetiro.fecha_entrega,
          fecha_retiro: this.nuevoRetiro.fecha_retiro,
          Retira_tutor: this.nuevoRetiro.Retira_tutor,
          fraccionado: this.nuevoRetiro.fraccionado,
          rp_hospitalizado: this.nuevoRetiro.Rp_hospitalizado,
          fecha_situacion: this.nuevoRetiro.fecha_situacion,
          Situacion: this.nuevoRetiro.situacion,
          Type: this.nuevoRetiro.type,
        };
        this.isSaving = true;
        try {
          await this.$store.dispatch("terapias/crearRetiro", datosReales);
          this.$router.push({ name: "entregaRetiros" });
        } catch (error) {
          console.error("Error al crear Retiro:", error);
        } finally {
          this.isSaving = false;
        }
      }
    },
    cancelar() {
      this.$router.push({ name: "entregaRetiros" });
    },
  },
  async created() {
    await this.getPatient(this.$route.params.id);
  },
};
</script>
