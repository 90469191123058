<template>
  <v-container>
    <h1 class="titulos">Monitoreo</h1>
    <v-tabs v-model="tab" slider-color="#1dc6d8" centered>
      <v-tab
        v-for="item in items"
        :key="item.tab"
        class="tabs"
        active-class="tab-activa"
        color="red"
      >
        <v-icon>{{ item.icono }}</v-icon
        >{{ item.tab }}
      </v-tab>
    </v-tabs>
    <v-card>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.tab">
          <component v-bind:is="item.content" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import InfoPacienteComponente from "./components/InfoPacienteComponente.vue";
import DiagnosticoComponente from "./components/DiagnosticoComponente.vue";
import EncuestasComponente from "./components/EncuestasComponente.vue";
import TratamientoComponente from "./components/TratamientoComponente.vue";
import LaboratorioComponente from "./components/LaboratorioComponente.vue";

export default {
  components: {
    InfoPacienteComponente,
    DiagnosticoComponente,
    LaboratorioComponente,
    EncuestasComponente,
    TratamientoComponente,
  },
  data() {
    return {
      tab: null,
      items: [
        {
          tab: "Información Paciente",
          content: "InfoPacienteComponente",
        },
        { tab: "Diagnóstico", content: "DiagnosticoComponente" },
        {
          tab: "Tratamiento y Medicamentos",
          content: "TratamientoComponente",
          icono: "mdi-pill-multiple",
        },
        { tab: "Laboratorio", content: "LaboratorioComponente" },
        { tab: "Encuestas", content: "EncuestasComponente" },
      ],
    };
  },
};
</script>
<style>
.tabs {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #a5a5a540;
}
.tab-activa {
  color: white !important;
  background-color: #1dc6d8;
}
</style>
