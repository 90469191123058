<template>
  <v-container>
    <h2>Encuesta</h2>
    <p class="sub-header-poll">
      A continuación encontrarás 3 preguntas relacionadas con el tratamiento del
      paciente para el VIH.
    </p>
    <v-form ref="form" @submit.prevent="siguiente" v-model="valid">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            label="Fecha encuesta"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-model="date"
            v-on="on"
            color="#1dc6d8"
          />
        </template>
        <v-date-picker
          v-model="date"
          :max="
            new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substring(0, 10)
          "
          min="1900-01-01"
          color="#1dc6d8"
        />
      </v-menu>
      <p class="pregunta-poll">
        1. Respecto al tratamiento para el VIH ¿Tuviste alguno de estos
        síntomas?
        <span class="sub-pregunta-poll">
          (Marca todos los que correspondan)
        </span>
      </p>

      <v-checkbox
        v-model="encuesta_sintomas"
        v-for="symptom in SINTOMAS"
        :key="symptom"
        :label="symptom"
        :value="symptom"
        color="#1dc6d8"
        class="custom-checkbox"
        required
        :rules="[(v) => v.length > 0 || 'Falta la respuesta a esta pregunta']"
      />
      <p class="pregunta-poll">
        2. Con respecto a la pregunta anterior, ¿Con qué intensidad?
      </p>
      <v-radio-group v-model="encuesta_intensidad">
        <v-radio
          v-for="n in INTENSIDAD"
          :key="n"
          :label="`${n}`"
          :value="n"
          color="#1dc6d8"
        />
      </v-radio-group>
      <p class="pregunta-poll">
        3. ¿Con qué frecuencia has sentido malestares asociados a tus
        medicamentos para el VIH?
      </p>
      <v-radio-group v-model="encuesta_frecuencia">
        <v-radio
          v-for="n in this.FRECUENCIA"
          :key="n"
          :label="`${n}`"
          :value="n"
          color="#1dc6d8"
        />
      </v-radio-group>
      <v-row justify="end">
        <v-col cols="auto">
          <v-btn type="submit" class="golden-button">Guardar</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "TratamientoEncuesta",
  data() {
    return {
      menu: false,
      valid: false,
      encuesta_sintomas: [],
      encuesta_intensidad: "",
      encuesta_frecuencia: "",
      date: new Date().toISOString().substring(0, 10),
      SINTOMAS: [
        "Dolor de cabeza",
        "Mareos",
        "Náuseas",
        "Diarrea",
        "Dolor abdominal",
        "Somnolencia",
        "Alergia a la piel",
        "Ninguno de los anteriores",
      ],
      INTENSIDAD: [
        "Leve (no interfiere con tus actividades habituales y no necesita medicación)",
        "Moderada (se necesita medicación para realizar actividades de la vida diaria)",
        "Severa (impide el desarrollo de las actividades habituales pese a tomar medicación)",
      ],
      FRECUENCIA: [
        "Nunca",
        "Raramente (cada 3-6 meses)",
        "Frecuentemente (al menos una vez al mes)",
        "Muy frecuentemente (al menos una vez a la semana)",
        "Constantemente (todos los días)",
      ],
    };
  },
  methods: {
    async siguiente() {
      this.$refs.form.validate();
      const {
        date,
        valid,
        encuesta_frecuencia,
        encuesta_intensidad,
        encuesta_sintomas,
      } = this;
      if (valid) {
        const pacienteId = this.$route.params["id"];
        // Accede a la lista de pacientes
        const pacientes = this.$store.state.patients.patients;

        // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
        const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

        const rut = paciente.rut

        const encuesta_sintomas_json = encuesta_sintomas.reduce((acc, item, index) => {
          acc[index + 1] = item; // Asigna cada elemento con un ID numérico, comenzando en 1
          return acc;
        }, {});
        const payload = {
                    paciente_nombre: rut,
                    register_poll_date: date,
                    Encuesta_1: encuesta_sintomas_json,
                    Encuesta_2: encuesta_intensidad,
                    Encuesta_3: encuesta_frecuencia,
                    method: "POST",
                };
        try {
            await this.$store.dispatch("patients/addEncuestaTrat", payload);
        } catch (error) {
            console.log("Algo falló: ", error);
        }
      } else {
        console.log("Error");
      }
    },
  },
};
</script>
<style>
.v-input--selection-controls__input .v-icon:not(.v-icon--active) {
  color: #1dc6d8;
}

.v-input--selection-controls__input .v-icon.v-icon--empty {
  background-color: #f0f0f0;
}
</style>
