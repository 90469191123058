<template>
  <section
    class="d-flex flex-column align-center justify-center custom-height"
    v-if="this.isLoading"
  >
    <v-progress-circular indeterminate :size="50" color="primary" />
    <h3>Cargando...</h3>
  </section>
  <section v-else>
    <!-- v-data-table component to display RNA measurements -->
    <v-data-table
      v-if="desserts"
      :headers="headers"
      :items="desserts"
      no-data-text="No hay mediciones disponibles"
      :footer-props="{ 'items-per-page-text': 'Registros por página' }"
    >
      <!-- Top slot for toolbar and dialogs -->
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title class="custom-toolbar-title">
            Mediciones RNA
          </v-toolbar-title>
          <v-spacer />
          <!-- Dialog for adding/editing RNA measurements -->
          <v-dialog v-model="dialog" max-width="600px" @click:outside="close()">
            <!-- Button to open the dialog -->
            <template #activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Nueva Medición RNA
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <!-- Date picker for RNA measurement date -->
                    <v-col cols="12" sm="6" md="4">
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                            required
                            :rules="[(v) => !!v || 'Falta fecha de medida']"
                            v-model="editedItem.rna_d"
                            label="Fecha de registro"
                            prepend-icon="mdi-calendar"
                            messages="Apretar en el año para ir a un año en especifico, lo mismo para mes"
                            readonly
                            no-title
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.rna_d"
                          v.model:active-picker="activePicker"
                          :max="maxDate"
                          min="1950-01-01"
                          color="primary"
                        />
                      </v-menu>
                    </v-col>
                    <!-- Input field for RNA measurement value -->
                    <v-col>
                      <v-text-field
                        v-model="editedItem.rna_v"
                        label="Medida RNA"
                        type="number"
                        :rules="[(v) => v > 0 || 'La medida debe ser positiva']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <!-- Action buttons for the dialog -->
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="close">
                  Cancelar
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  :disabled="!isFormValid"
                  @click="editedItem.id ? editar() : add()"
                >
                  {{ editedItem.id ? "Editar" : "Guardar" }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Dialog for confirming deletion of an RNA measurement -->
          <v-dialog v-model="dialogDelete" max-width="600px">
            <v-card>
              <v-card-title class="text-h7"
                >Estas seguro que quieres eliminar esta medición?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >SI</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <!-- Template for action buttons in each row -->
      <template #[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false, // Controls visibility of add/edit dialog
      dialogDelete: false, // Controls visibility of delete confirmation dialog
      menu: false, // Controls visibility of date picker menu
      editedItem: { id: null, rna_d: null, rna_v: null, paciente: null }, // Stores the item being edited
      headers: [
        { text: "Fecha", value: "rna_d" },
        { text: "Medición RNA", value: "rna_v" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    // Map Vuex actions to local methods
    ...mapActions("patients", [
      "addRNA",
      "editRNA",
      "deleteRNA",
      "loadRNAData",
    ]),
    // Add a new RNA measurement
    async add() {
      const { rut } = this.patientVariables;
      const { rna_d, rna_v } = this.editedItem;
      const response = await this.addRNA({ paciente: rut, rna_d, rna_v });
      this.editedItem = response;
      this.desserts.push(this.editedItem);
      this.close();
    },
    // Edit an existing RNA measurement
    async editar() {
      const { id, paciente, rna_d, rna_v } = this.editedItem;
      const response = await this.editRNA({
        id,
        body: { paciente, rna_d, rna_v },
      });
      const edited_item = this.desserts.findIndex((e) => e.id == id);
      Object.assign(this.desserts[edited_item], response);
      this.close();
    },
    // Prepare to edit an item
    editItem(item) {
      this.editedItem = { ...item };
      this.dialog = true;
    },
    // Prepare to delete an item
    deleteItem(item) {
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    // Confirm and execute item deletion
    deleteItemConfirm() {
      const { id } = this.editedItem;
      const delete_item = this.desserts.findIndex((e) => e.id == id);
      this.desserts.splice(delete_item, 1);
      this.deleteRNA({ id });
      this.dialogDelete = false;
    },
    // Close the add/edit dialog
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = {
          id: null,
          rna_d: null,
          rna_v: null,
          paciente: null,
        };
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = {
          id: null,
          rna_d: null,
          rna_v: null,
          paciente: null,
        };
      });
    },
    // Load RNA data for the current patient
    async loadRNA() {
      const { rut } = this.patientVariables;
      await this.loadRNAData({ rut });
    },
  },
  computed: {
    // Map Vuex getters to local computed properties
    ...mapGetters("patients", [
      "patientVariables",
      "getPatientRNA",
      "isLoading",
    ]),
    // Determine the title for the add/edit dialog
    formTitle() {
      return this.editedItem.id ? "Editar medición" : "Nueva medición";
    },
    // Check if the form is valid
    isFormValid() {
      return this.editedItem.rna_d && this.editedItem.rna_v > 0;
    },
    // Get the maximum allowed date (today)
    maxDate() {
      return new Date().toISOString().substr(0, 10);
    },
    desserts() {
      return this.$store.getters["patients/getPatientRNA"];
    },
  },
  created() {
    const { rut } = this.patientVariables;
    this.loadRNAData({ rut });
  },
};
</script>
