<template>
  <section v-if="years.length > 0">
    <h1>Alcohol y otras Drogas</h1>
    <v-row
      ><v-col
        ><h3>Año {{ year }}</h3></v-col
      ><v-col
        ><v-select
          v-model="year"
          :items="years"
          @change="
            () => {
              patientCD4();

              getPatientPAC();
              getPatientTAR();
            }
          "
        ></v-select></v-col
    ></v-row>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th
              class="text-left"
              v-for="record in predictionAyD"
              :key="record.year"
            >
              {{ record.year }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th
              class="text-left"
              v-for="record in predictionAyD"
              :key="record.year"
            >
              {{ (record.probabilidad * 100).toFixed(3) }}%
            </th>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-form @submit.prevent="saveData">
      <!--------------------------------->
      <!--- SEXO; GENERO; ORIENTACIÓN---->
      <v-row>
        <v-col
          ><v-radio-group
            class="modify-input"
            v-model="patientVariables.Genero"
            required
            :rules="[(v) => !!v || 'Falta identidad de genero']"
          >
            <template v-slot:label>
              <strong>Identidad de Genero</strong>
            </template>
            <v-radio label="Cis" value="Cis"></v-radio>
            <v-radio label="Trans" value="Trans"></v-radio>
            <v-radio label="Otro" value="Otro"></v-radio>
            <v-radio label="No binario" value="Otro"></v-radio>
            <v-radio
              label="Sexo fluido"
              value="Otro"
            ></v-radio> </v-radio-group></v-col
        ><v-col>
          <v-radio-group
            v-model="patientVariables.sex"
            required
            :rules="[(v) => !!v || 'Falta sexo']"
          >
            <template v-slot:label>
              <strong>Sexo</strong>
            </template>
            <v-radio
              v-for="n in ['F', 'M', 'T']"
              :key="n"
              :label="`${n}`"
              :value="n"
            >
            </v-radio> </v-radio-group
        ></v-col>
        <v-col
          ><v-radio-group
            class="modify-input"
            v-model="patientVariables.CONDUSEXUAL"
            required
            :rules="[(v) => !!v || 'Falta orientación sexual']"
            :disabled="!statusForm"
          >
            <template v-slot:label>
              <strong>Orientación Sexual</strong>
            </template>
            <v-radio
              v-for="n in [
                'BISEXUAL',
                'HETEROSEXUAL',
                'HOMOSEXUAL',
                'SIN DATO',
              ]"
              :key="n"
              :label="`${n}`"
              :value="n"
              :disabled="!statusForm"
            ></v-radio></v-radio-group
        ></v-col>
      </v-row>
      <!--------------------------------->
      <v-row
        ><v-col>
          <v-subheader> <strong> Mecanismo de infección </strong> </v-subheader
          ><v-select
            v-model="patientVariables.mode"
            label="Mode"
            :items="items_mode_2"
            item-text="descripcion"
            item-value="mode"
            return-object
            single-line
            required
            :rules="[(v) => !!v || 'Falta modo de infección']"
          >
          </v-select></v-col
        ><!-- <v-col
            ><v-subheader> <strong> Modo de transmisión (mode_oth)</strong> </v-subheader
            ><v-select
              v-model="patientVariables.mode_oth"
              label="Mode_oth"
              :items="items_mode_oth"
              item-text="traduccion"
              item-value="original"
              return-object
              single-line
              required
              :rules="[(v) => !!v || 'Falta modo de transmisión']"
            >
            </v-select></v-col
        > --></v-row
      >

      <v-row>
        <v-col>
          <v-radio-group v-model="patientVariables.ART_at_baseline">
            <template v-slot:label>
              <strong>Tratamiento Antiretroviral al comienzo</strong>
            </template>
            <v-radio
              v-for="n in ['CON TAR', 'SIN TAR']"
              :key="n"
              :label="`${n}`"
              :value="n"
              :disabled="!statusForm"
            >
            </v-radio>
          </v-radio-group>
        </v-col>
        <v-col>
          <v-subheader>
            <strong> Etapa CDC (cdc_stage)</strong>
          </v-subheader>
          <v-select
            v-model="patientVariables.cdcstage"
            label="Cdcstage"
            :items="items_cdc"
            return-object
            single-line
            :disabled="!statusForm"
            required
            :rules="[(v) => !!v || 'Falta modo cdc stage']"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row
        ><v-col>
          <v-radio-group
            v-model="patientVariables.aids_y"
            required
            :rules="[(v) => !!v || 'Falta aids']"
            :disabled="!statusForm"
          >
            <template v-slot:label>
              <strong>¿Está en etapa de sida?</strong>
            </template>
            <v-radio label="Si" value="1.0"></v-radio>
            <v-radio label="No" value="2.0"></v-radio>
            <v-radio label="Desconocido" value="9.0"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col>
          <v-radio-group v-model="patientVariables.clinicaltrial_y">
            <template v-slot:label>
              <strong>Ha estado en algún ensayo clinico?</strong>
            </template>
            <v-radio
              v-for="n in ['2.0', '1.0', '0.0']"
              :key="n"
              :label="`${n}`"
              :value="n"
              :disabled="!statusForm"
            ></v-radio
          ></v-radio-group>
        </v-col>
      </v-row>

      <v-subheader>
        <strong v-if="cd4.length != 0"> Estadisticos de CD4</strong>
      </v-subheader>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Año</th>
              <th class="text-left">Conteo</th>
              <th class="text-left">Promedio</th>
              <th class="text-left">Minimo</th>
              <th class="text-left">Máximo</th>
              <th class="text-left">Mediana</th>
              <th class="text-left">Std</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="tex-left">
                {{ year }}
              </th>
              <th class="text-left">
                {{ cd4.conteo }}
              </th>
              <th class="text-left">
                {{ cd4.promedio }}
              </th>
              <th class="text-left">
                {{ cd4.minimo }}
              </th>
              <th class="text-left">
                {{ cd4.maximo }}
              </th>
              <th class="text-left">
                {{ cd4.mediana }}
              </th>
              <th class="text-left">{{ cd4.std }}</th>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-radio-group
        v-model="patientVariables.Derivar"
        required
        :rules="[(v) => !!v || 'Falta derivar']"
        ><template v-slot:label>
          <strong>¿Paciente derivado desde otro centro?</strong>
        </template>
        <v-radio label="No" value="No deriva"></v-radio>
        <v-radio label="Si" value="True"></v-radio>
        <!-- <v-radio v-for="n in ['No deriva', 'True',]" :key="n" :label="`${n}`" :value="n"></v-radio
        > --></v-radio-group
      >
      <v-row>
        <v-col
          ><v-menu
            ref="menu_nacimiento"
            v-model="menu_nacimiento"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                required
                :rules="[(v) => !!v || 'Falta fecha de nacimiento']"
                v-model="patientVariables.birth_d"
                label="Fecha de nacimiento"
                prepend-icon="mdi-calendar"
                messages="Apretar en el año para ir a un año en especifico, lo mismo para mes"
                readonly
                no-title
                v-bind="attrs"
                v-on="on"
                :disabled="!statusForm"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="patientVariables.birth_d"
              v.model:active-picker="activePicker"
              :max="
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substr(0, 10)
              "
              min="1950-01-01"
              color="primary"
            />
          </v-menu>
        </v-col>
        <v-col>
          <p><strong>Edad: </strong>{{ patientVariables.edad.toFixed(2) }}</p>
        </v-col>
        <v-col>
          <strong>Segmento Etario:</strong>
          {{ patientVariables.Segmento_etario }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-radio-group v-model="patientPAC[0].accion">
            <template v-slot:label><strong>Acciones</strong></template>
            <v-radio
              v-for="n in [
                'Cambio',
                'Continuacion',
                'Continuacion/cambio',
                'Reinicio',
                'Reinicio/cambio',
                'Inicio',
              ]"
              :key="n"
              :label="`${n}`"
              :value="n"
              :disabled="!statusForm"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col>
          <v-subheader>
            <strong>Motivo</strong>
          </v-subheader>
          <v-select
            v-model="patientPAC[0].motivo"
            :items="motivo_choices"
            label="Motivo de la acción"
            return-object
            single-line
            :disabled="!statusForm"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <strong>Número Tratamientos</strong>
          <v-text-field
            v-model="patientAuditorias[0].numtar"
            label="Cantidad de tratamientos TAR que ha tenido"
            hint="Cantidad de tratamientos TAR que ha tenido"
            hide-details
            single-line
            type="number"
            :disabled="!statusForm"
          ></v-text-field>
        </v-col>
        <v-col>
          <strong>Razón de cambio o suspensión TAR</strong>
          <v-text-field
            v-model="patientAuditorias[0].art_rs"
            label="Razón de cambio o suspensión TAR"
            hide-details
            single-line
            type="number"
            step="0.1"
            :disabled="!statusForm"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="!patientVariables.death_d"
        ><v-col
          ><strong>Esta vivo el paciente?</strong>
          <p>Vivo</p></v-col
        >
        <v-col
          ><strong>Agregar Fecha de Muerte</strong>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="patientVariables.death_d"
                label="Fecha de muerte"
                prepend-icon="mdi-calendar"
                readonly
                no-title
                v-bind="attrs"
                v-on="on"
                :disabled="!statusForm"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="patientVariables.death_d"
              v.model:active-picker="activePicker"
              :max="
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substr(0, 10)
              "
              min="1950-01-01"
              @change="save"
            >
            </v-date-picker></v-menu></v-col
      ></v-row>
      <v-row v-else
        ><v-col
          ><strong>Paciente muerto </strong> ({{
            patientVariables.death_d
          }})</v-col
        ></v-row
      >
      <v-row>
        <v-col>
          <v-subheader>
            <strong>Nivel educacional</strong>
          </v-subheader>
          <v-select
            required
            :rules="[(v) => !!v || 'Falta nivel educacional']"
            v-model="patientVariables.nivel_educacional"
            :hint="`${patientVariables.nivel_educacional}`"
            :items="items_educacion"
            item-text="nombre"
            item-value="nombre"
            label="Nivel educacional"
            return-object
            single-line
            :disabled="!statusForm"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-radio-group
            v-model="patientVariables.Tiene_Autorizado"
            required
            :rules="[(v) => !!v || 'Falta autorización']"
          >
            <template v-slot:label>
              <strong>¿Tiene tutor que retire sus fármacos?</strong>
            </template>
            <v-radio
              v-for="n in ['Verdadero', 'Falso', 'Sin dato']"
              :key="n"
              :label="`${n}`"
              :value="n"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-subheader>
            <strong> Previsión</strong>
          </v-subheader>
          <v-select
            required
            :rules="[(v) => !!v || 'Falta fecha previsión de salud']"
            v-model="patientVariables.Prevision"
            :items="items_prevision"
            label="Prevision de salud"
            return-object
            single-line
            :disabled="!statusForm"
          >
          </v-select>
        </v-col>
        <v-col>
          <v-subheader>
            <strong>Situación Laboral</strong>
          </v-subheader>
          <v-select
            required
            :rules="[(v) => !!v || 'Falta situación laboral']"
            v-model="patientVariables.employment_description"
            :items="items_laboral"
            label="Situación Laboral"
            return-object
            single-line
            :disabled="!statusForm"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-radio-group
            v-model="patientVariables.hla"
            required
            :rules="[(v) => !!v || 'Falta resultado hla']"
            :disabled="!statusForm"
          >
            <template v-slot:label>
              <strong>Resultado prueba Hla</strong>
            </template>
            <v-radio
              v-for="n in ['Negativo', 'Positivo', 'Desconocido']"
              :key="n"
              :label="`${n}`"
              :value="n"
              :disabled="!statusForm"
            ></v-radio
          ></v-radio-group>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-subheader><strong>Seleccionar Region</strong></v-subheader>
          <v-select
            required
            :rules="[(v) => !!v || 'Falta comuna']"
            v-model="patientVariables.Region"
            :items="Object.keys(this.comunaRegiones).sort()"
            item-text="nombre"
            item-value="nombre"
            label="Region"
            return-object
            single-line
          ></v-select>
        </v-col>
        <v-col>
          <v-subheader>
            <strong>Seleccionar comuna</strong>
          </v-subheader>
          <v-select
            required
            :rules="[(v) => !!v || 'Falta comuna']"
            v-model="patientVariables.Comuna"
            :items="
              patientVariables.Region
                ? Object.values(this.comuna(patientVariables.Region)).sort()
                : ['Elija Region primero']
            "
            item-text="nombre"
            item-value="nombre"
            label="Comuna"
            return-object
            single-line
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-radio-group
            v-model="patientAuditorias[0].PROTOCOLO"
            :disabled="!statusForm"
          >
            <template v-slot:label>
              <strong>Protocolo</strong>
            </template>
            <v-radio label="Si" :value="0"></v-radio>
            <v-radio label="No" :value="1"></v-radio>
            <v-radio label="Desconocido" :value="2"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <strong>Dias sin tratamiento: </strong>
          {{
            patientVariables.dias_sin_tar_anual[this.year]
              ? patientVariables.dias_sin_tar_anual[this.year]
              : "No hay datos para este año"
          }}
        </v-col>
      </v-row>
      <v-divider vertical></v-divider>
      <h3>TAR utilizado</h3>
      <v-row class="mb-10">
        <!--Drogas Utilizada-->
        <v-col
          ><!--Droga 1-->
          <v-select
            v-model="patientAuditorias[0].droga_1"
            :items="drogas"
            label="Droga 1"
            return-object
            single-line
            :disabled="!statusForm"
          ></v-select>
          <!-- <v-select
              v-model="droga_1_pac"
              :items="drogas_pac"
              label="Droga 1"
              return-object
              single-line
            ></v-select>
            <v-select
              v-model="posologia_pac_1"
              :items="posologia_pac"
              label="Posología droga 1"
              return-object
              single-line
            >
            </v-select> -->
        </v-col>
        <v-col
          ><!--Droga 2-->
          <v-select
            v-model="patientAuditorias[0].droga_2"
            :items="drogas"
            label="Droga 2"
            return-object
            single-line
            :disabled="!statusForm"
          ></v-select>
          <!--  <v-select
              v-model="droga_2_pac"
              :items="drogas_pac"
              label="Droga 2"
              return-object
              single-line
            ></v-select>
            <v-select
              v-model="posologia_pac_2"
              :items="posologia_pac"
              label="Posología droga 2"
              return-object
              single-line
            >
            </v-select> -->
        </v-col>
        <v-col
          ><!--Droga 3-->
          <v-select
            v-model="patientAuditorias[0].droga_3"
            :items="drogas"
            label="Droga 3"
            return-object
            single-line
            :disabled="!statusForm"
          ></v-select>
          <!-- <v-select
              v-model="droga_3_pac"
              :items="drogas_pac"
              label="Droga 3"
              return-object
              single-line
            ></v-select>
            <v-select
              v-model="posologia_pac_3"
              :items="posologia_pac"
              label="Posología droga 3"
              return-object
              single-line
            >
            </v-select> -->
        </v-col>
        <v-col
          ><!--Droga 4-->
          <v-select
            v-model="patientAuditorias[0].droga_4"
            :items="drogas"
            label="Droga 4"
            return-object
            single-line
            :disabled="!statusForm"
          ></v-select>
          <!-- <v-select
              v-model="droga_4_pac"
              :items="drogas_pac"
              label="Droga 4"
              return-object
              single-line
            ></v-select>
            <v-select
              v-model="posologia_pac_4"
              :items="posologia_pac"
              label="Posología droga 4"
              return-object
              single-line
            >
            </v-select> -->
        </v-col>
      </v-row>
      <v-divider vertical></v-divider>
      <v-row>
        <v-col
          ><v-menu
            ref="menu_enrol_d"
            v-model="menu_enrol_d"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="patientVariables.enrol_d"
                label="Fecha de enrolamiento en la fundación"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :disabled="!statusForm"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="patientVariables.enrol_d"
              v.model:active-picker="activePicker"
              :max="
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substr(0, 10)
              "
              min="1950-01-01"
              @change="save('menu_enrol_d')"
            >
            </v-date-picker
          ></v-menu>
          <p>
            <strong
              >({{ patientVariables.enrol_years.toFixed(2) }} Años)</strong
            >
          </p>
        </v-col>
        <v-col>
          <v-menu
            ref="menu_diagnostico"
            v-model="menu_diagnostico"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="patientVariables.diagnosis_d"
                label="Fecha diagnostico VIH"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :disabled="!statusForm"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="patientVariables.diagnosis_d"
              v.model:active-picker="activePicker"
              :max="
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substr(0, 10)
              "
              min="1950-01-01"
              @change="save('menu_diagnostico')"
            >
            </v-date-picker
          ></v-menu>
          <p>
            <strong
              >({{ patientVariables.diagnosis_years.toFixed(2) }} Años)</strong
            >
          </p>
        </v-col>
      </v-row>
      <v-subheader
        >Número de enfermedades oportunistas (pasadas y actuales)</v-subheader
      >
      <v-row>
        <v-col
          ><p>
            <strong>Cantidad de POP's: </strong>{{ patientVariables.pop }}
          </p></v-col
        >
        <v-col
          ><p>
            <strong>Ausencia de POP: </strong
            >{{ patientVariables.ausencia_pop ? "Sí" : "No" }}
          </p></v-col
        >
      </v-row>

      <v-btn type="submit" :disabled="!statusForm">Guardar Datos</v-btn>
      <v-btn @click="predict" :disabled="!statusForm"> Predecir </v-btn>
      <!-- <p>{{ patientVariables }}</p>
      <p>{{ patientAuditorias }}</p>
      <p>{{ patientTAR }}</p>
      <p>{{ patientPAC }}</p> -->
    </v-form>
    <v-snackbar
      v-if="alert"
      v-model="alert"
      :color="snackbar_color"
      height="20"
    >
      <span v-if="!prediction"
        ><ul>
          <li v-for="i in message_error" :key="i">{{ i }}</li>
        </ul></span
      >
      <span v-else
        >La adhrencia calculada durante el año {{ year }} fue de
        <b>{{ prediction }}%</b></span
      >
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="close"> Cerrar </v-btn>
        <v-btn color="black" text v-bind="attrs" @click="scrollToTop">
          Ir arriba
        </v-btn>
      </template>
    </v-snackbar>
  </section>
  <section v-else>
    <v-row>
      <v-col>
        <h3>No hay años disponibles de subpredictores para este paciente</h3>
        <p>
          En futuras versiones de este software se integrara la posibilidad de
          clasificar nuevos pacientes.
        </p>
      </v-col></v-row
    >
  </section>
</template>
<script>
export default {
  data() {
    return {
      alert: false,
      prediction: null,
      year: new Date().getFullYear(),
      death_d: null,
      menu: false,
      menu_nacimiento: false,
      menu_retiro: false,
      menu_entrega: false,
      menu_diagnostico: false,
      menu_enrol_d: false,
      patientTAR: [{ Retira_tutor: "false", Situacion_varios: "Activo" }],
      patientAuditorias: [{ numtar: 0 }],
      patientPAC: [{ accion: null, motivo: null }],
    };
  },
  methods: {
    close() {
      this.alert = !this.alert;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    async predict() {
      const payload = {
        rut: this.patientVariables.rut,
        year: this.year,
        predictor: "AyD",
      };
      console.log("this values are send to the server", payload);
      this.$store.dispatch("patients/predictAyD", payload).then((res) => {
        console.log("resa", res);
        if (res.status === 400 || res.status === 500) {
          console.log("res", res);
          console.log(res.data);
          this.message_error = res.data.errores;
          this.alert = true;
          this.snackbar_color = "error";
        } else {
          this.$store.dispatch(
            "patients/loadPredictions",
            this.patientVariables.rut
          );
          this.prediction = (res.data[0].probability * 100).toFixed(3);
          this.message_error = ["Predicción bien hecha"];
          this.alert = true;
          this.snackbar_color = "success";
        }
      });
    },
    patientCD4() {
      let values = this.$store.getters["patients/patientCD4"];
      const value = values.filter((el) => {
        const d = new Date(el.cd4_d);
        return d.getFullYear() == this.year;
      });
      values = Object.keys(value).map(function (key) {
        return value[key].cd4_v;
      });
      if (values.length > 0) {
        this.cd4 = {
          values: values,
          conteo: values.length,
          promedio: values.reduce((a, b) => a + b) / values.length,
          minimo: Math.min.apply(Math, values),
          maximo: Math.max.apply(Math, values),
          mediana: this.median(values),
          std: this.getStandardDeviation(values),
        };
      }
    },
    median(numbers) {
      const sorted = Array.from(numbers).sort((a, b) => a - b);
      const middle = Math.floor(sorted.length / 2);

      if (sorted.length % 2 === 0) {
        return (sorted[middle - 1] + sorted[middle]) / 2;
      }

      return sorted[middle];
    },
    getStandardDeviation(array) {
      const n = array.length;
      const mean = array.reduce((a, b) => a + b) / n;
      return Math.sqrt(
        array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
      );
    },
    //********************************************************************************************/
    //GETS
    getPatientPAC() {
      this.patientPAC = [{ accion: "Continuacion", motivo: "NAIVE" }];
      let values = this.$store.getters["patients/patientPAC"].results;

      const value = values.filter((el) => {
        const d = new Date(el.Fecha_accion);
        return d.getFullYear() == this.year;
      });
      if (value.length > 0) {
        values = Object.keys(value).map(function (key) {
          return value[key];
        });
        this.patientPAC = values;
      }
    },
    getAuditoria() {
      this.patientAuditorias = [
        {
          numtar: 0,
          art_rs: 1,
          droga_1: "TDF",
          droga_2: "FTC",
          droga_3: "RPV",
          droga_4: null,
          PROTOCOLO: 2,
        },
      ];
      let values = this.$store.getters["patients/patientAuditorias"].results;
      const value = values.filter((el) => {
        const d = new Date(el.fechaini);
        return d.getFullYear() == this.year;
      });
      if (value.length > 0) {
        values = Object.keys(value).map(function (key) {
          return value[key];
        });
        this.patientAuditorias = values;
      }
    },
    getPatientTAR() {
      this.patientTAR = [{ Retira_tutor: false, Situacion_varios: "Activo" }];
      let values = this.$store.getters["patients/patientTAR"].results;
      const value = values.filter((el) => {
        const d = new Date(el.fecha_entrega);
        return d.getFullYear() == this.year;
      });
      if (value.length > 0) {
        values = Object.keys(value).map(function (key) {
          return value[key];
        });
        this.patientTAR = values;
      }
    },
    //********************************************************************************************/
    comuna(comuna) {
      try {
        return Object.values(this.comunaRegiones[comuna]);
      } catch {
        return "Otra";
      }
    },
  },
  computed: {
    comunaRegiones() {
      return this.$store.getters["datosVariables/comunaRegiones"];
    },
    predictionAyD() {
      const value = this.$store.getters["patients/patientPredictions"];
      let predicciones = value;

      predicciones = predicciones.filter((el) => {
        return el.predictor == "AyD";
      });
      predicciones.sort(function (a, b) {
        return a.year - b.year;
      });
      return predicciones;
    },
    years() {
      let values =
        this.$store.getters["patients/patientVariables"].available_years;
      values = values.sort(function (a, b) {
        return a - b;
      });
      return values;
    },
    maxYear() {
      return 2000;
    },
    statusForm() {
      return this.$store.getters["patients/statusForm"];
    },
    patientVariables() {
      return this.$store.getters["patients/patientVariables"];
    },
    items_mode_2() {
      return this.$store.getters["datosVariables/items_mode_2"];
    },
    items_mode_oth() {
      return this.$store.getters["datosVariables/items_mode_oth"];
    },
    items_cdc() {
      return this.$store.getters["datosVariables/itemsCDC"];
    },
    items_educacion() {
      return this.$store.getters["datosVariables/items_educacion"];
    },
    items_laboral() {
      return this.$store.getters["datosVariables/items_laboral"];
    },
    items_prevision() {
      return this.$store.getters["datosVariables/items_prevision"];
    },
    comunas() {
      return this.$store.getters["datosVariables/comunas"];
    },
    motivo_choices() {
      return this.$store.getters["datosVariables/motivo_choices"];
    },
    drogas() {
      return this.$store.getters["datosVariables/drogas"];
    },
  },
  async created() {
    this.year = Math.min(
      ...this.$store.getters["patients/patientVariables"].available_years
    );
    this.patientCD4();
    await this.getAuditoria();
    await this.getPatientPAC();
    await this.getPatientTAR();
  },
};
</script>
