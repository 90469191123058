<template>
  <v-container>
    <h2>Observaciones</h2>
    <v-form ref="form" @submit.prevent="siguiente" v-model="valid">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            label="Fecha observación"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-model="date"
            v-on="on"
            color="#1dc6d8"
          />
        </template>
        <v-date-picker
          v-model="date"
          :max="
            new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substring(0, 10)
          "
          min="1900-01-01"
          color="#1dc6d8"
        />
      </v-menu>
      <v-text-field
        v-model="observaciones"
        label="Escriba tus observaciones"
        :rules="[(v) => !!v || 'Este campo es requerido']"
        required
        outlined
      />
      <v-row justify="end">
        <v-col cols="auto">
          <v-btn type="submit" class="golden-button">Guardar</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "ObservacionesEncuesta",
  data() {
    return {
      menu: false,
      date: new Date().toISOString().substring(0, 10),
      valid: false,
      observaciones: "",
    };
  },
  methods: {
    async siguiente() {
      this.$refs.form.validate();
      const { valid, date, observaciones } = this;
      if (valid) {
        const pacienteId = this.$route.params["id"];
        // Accede a la lista de pacientes
        const pacientes = this.$store.state.patients.patients;

        // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
        const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

        const rut = paciente.rut

        const payload = {
                    paciente_nombre: rut,
                    observacion: observaciones,
                    register_poll_date: date,
                    method: "POST",
                };
        try {
            await this.$store.dispatch("patients/addEncuestaObs", payload);
        } catch (error) {
            console.log("Algo falló: ", error);
        }
      } else {
        console.log("Error");
      }
    },
  },
};
</script>
