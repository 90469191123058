export default{
    posologia_pac(state){
        return state.posologia_pac
    },
    itemsCDC(state){
        return state.items_cdc
    },
    comunas(state){
        return state.comunas
    },
    comunas_7(state){
        return state.comunas_7
    },
    items_mode_2(state){
        return state.items_mode_2
    },
    items_mode_oth(state){
        return state.items_mode_oth
    },
    items_educacion(state){
        return state.items_educacion
    },
    items_laboral(state){
        return state.items_laboral
    },
    items_prevision(state){
        return state.items_prevision
    },
    motivo_choices(state){
        return state.motivo_choices
    },
    drogas(state){
        return state.drogas
    },
    protocolo(state){
        return state.PROTOCOLO_choices
    },
    drogas_pop_choices(state){
        return state.drogas_pop_choices
    },
    comunaRegiones(state){
        return state.comunaRegiones
    },
    
    
}