<template>
  <v-container>
    <v-dialog
      v-model="addEntregaDialog"
      max-width="600px"
      @close:outside="close"
      content-class="elevation-0"
    >
      <AddEntrega />
    </v-dialog>
    <v-row align="center" justify="space-between" class="mb-4">
      <v-col cols="auto">
        <h1>Lista de entregas/retiros</h1>
      </v-col>
      <v-col cols="auto">
        <v-btn color="primary" @click="crearEntrega">
          <v-icon left>mdi-plus</v-icon>
          Nueva entrega
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="Entregas_pac"
      :items-per-page="5"
      class="elevation-1"
    >
      <template #item="{ item }">
        <tr>
          <td>{{ item.n_receta }}</td>
          <td>{{ item.fecha_entrega }}</td>
          <td>{{ item.fecha_retiro }}</td>
          <td>
            <!-- <v-btn small color="primary" @click="actualizarTerapia(item.id)">
                    Editar
                </v-btn> -->
            <v-btn small color="error" @click="eliminarEntrega(item.uuid)">
              Eliminar
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AddEntrega from "./AddEntrega.vue";
export default {
  components: {
    AddEntrega,
  },
  data() {
    return {
      addEntregaDialog: false,
      headers: [
        { text: "Receta", value: "n_receta" },
        { text: "Fecha Entrega", value: "fecha_entrega" },
        { text: "Fecha Retiro", value: "fecha_retiro" },
        { text: "Acciones", value: "acciones", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState("terapias", ["entregas"]),
    Entregas_pac() {
      return this.entregas.map((entrega) => ({
        ...entrega,
      }));
    },
  },
  methods: {
    ...mapActions("terapias", ["fetchEntregas", "eliminarEntrega"]),
    crearEntrega() {
      this.addEntregaDialog = true;
    },
    close() {
      this.addEntregaDialog = false;
    },
  },
  created() {
    // Imprime el id del paciente (codigoconasida) desde la ruta
    const pacienteId = this.$route.params["id"];

    // Accede a la lista de pacientes
    const pacientes = this.$store.state.patients.patients;

    // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
    const paciente = pacientes.find((p) => p.codigoconasida === pacienteId);

    this.fetchEntregas(paciente.rut);
  },
};
</script>
