var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('h2',[_vm._v("Encuesta")]),_c('p',{staticClass:"sub-header-poll"},[_vm._v(" A continuación las siguientes preguntas sobre el diagnóstico del paciente ")]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.siguiente.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha encuesta","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"max":new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substring(0, 10),"min":"1900-01-01"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('p',{staticClass:"pregunta-poll"},[_vm._v(" 1. ¿Tienes actualmente alguna de las siguientes condiciones crónicas (diagnosticadas)? "),_c('span',{staticClass:"sub-pregunta-poll"},[_vm._v(" (Marca todas las que correspondan) ")])]),_vm._l((this.ENFERMEDADES),function(n){return _c('v-checkbox',{key:n,attrs:{"label":`${n}`,"value":n,"color":"#1dc6d8","required":"","rules":[(v) => !!v || 'Falta la respuesta a esta pregunta']},model:{value:(_vm.encuesta_enfermedad),callback:function ($$v) {_vm.encuesta_enfermedad=$$v},expression:"encuesta_enfermedad"}})}),_c('p',{staticClass:"pregunta-poll"},[_vm._v(" 2. En el caso de presentar otra enfermedad indique cual? (antes de comenzar el tratamiento) ")]),_c('v-text-field',{attrs:{"label":"Escriba su respuesta","outlined":"","rules":[
        (v) =>
          /^[a-zA-ZÀ-ÿ\s]+$/.test(v) ||
          'Solo se permiten letras, espacios y tildes',
      ]},model:{value:(_vm.otra_enfermedad),callback:function ($$v) {_vm.otra_enfermedad=$$v},expression:"otra_enfermedad"}}),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"golden-button",attrs:{"type":"submit","width":"xl"}},[_vm._v("Guardar")])],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }