export default {
    namespaced: true,
    state: {
        show: false,
        message: '',
        color: 'success'
    },
    mutations: {
        showToast(state, { message, color }) {
            state.show = true
            state.message = message
            state.color = color || 'success'
        },
        hideToast(state) {
            state.show = false
        }
    },
    actions: {
        showToast({ commit }, { message, color }) {
            commit('showToast', { message, color })
        },
        hideToast({ commit }) {
            commit('hideToast')
        }
    }
}