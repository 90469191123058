<template>
  <v-container>
    <h2>Encuesta</h2>
    <p class="sub-header-poll">
      A continuación encontrarás 5 preguntas relacionadas con el estado animico
      del paciente.
    </p>
    <v-form ref="form" @submit.prevent="siguiente" v-model="valid">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            label="Fecha encuesta"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-model="date"
            v-on="on"
            color="#1dc6d8"
          />
        </template>
        <v-date-picker
          v-model="date"
          :max="
            new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substring(0, 10)
          "
          min="1900-01-01"
          color="#1dc6d8"
        />
      </v-menu>
      <p class="pregunta-poll">
        1. ¿Qué tan a menudo ha sentido cada uno de los siguientes síntomas
        durante las cuatro últimas semanas? ¿Poco interés o placer para hacer
        cosas?
      </p>
      <v-radio-group
        v-model="encuesta_placer"
        required
        :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
      >
        <v-radio
          v-for="n in this.FRECUENCIA"
          :key="n"
          :label="`${n}`"
          :value="n"
          color="#1dc6d8"
        />
      </v-radio-group>
      <p class="pregunta-poll">
        2. Qué tan a menudo ha sentido cada uno de los siguientes síntomas
        durante las cuatro últimas semanas? Se siente deprimido, irritado y sin
        esperanza
      </p>
      <v-radio-group
        v-model="encuesta_depresion"
        required
        :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
      >
        <v-radio
          v-for="n in this.FRECUENCIA"
          :key="n"
          :label="`${n}`"
          :value="n"
          color="#1dc6d8"
        />
      </v-radio-group>
      <p class="pregunta-poll">
        3. Durante las últimas 4 semanas, ¿Con qué frecuencia su salud física o
        los problemas emocionales han dificultado sus actividades laborales?
      </p>
      <v-radio-group
        v-model="encuesta_dificultad_laboral"
        required
        :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
      >
        <v-radio
          v-for="n in this.FRECUENCIA"
          :key="n"
          :label="`${n}`"
          :value="n"
          color="#1dc6d8"
        />
      </v-radio-group>
      <p class="pregunta-poll">
        4. ¿Alguna vez algún médico o especialista le ha dicho que tiene
        depresión?
      </p>
      <v-radio-group
        v-model="encuesta_depresion_medico"
        required
        :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
      >
        <v-radio
          v-for="n in this.FECHA_DIAGNOSTICO"
          :key="n"
          :label="`${n}`"
          :value="n"
          color="#1dc6d8"
        />
      </v-radio-group>
      <p class="pregunta-poll">
        5. En tu vida cotidiana, ¿Cuán a menudo sientes que cuentas con alguna
        persona que te muestre amor y afecto? (ejemplo: esposa/o, compañero/a
        hijos/as, amigos/as, etc.)
      </p>
      <v-radio-group v-model="encuesta_red_apoyo" required :rules="rules">
        <v-radio
          v-for="n in this.COTIDIANIDAD"
          :key="n"
          :label="`${n}`"
          :value="n"
          color="#1dc6d8"
        />
      </v-radio-group>
      <v-row justify="end">
        <v-col cols="auto">
          <v-btn type="submit" class="golden-button">Guardar</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "TrastornosAnimoEncuesta",
  data() {
    return {
      menu: false,
      valid: false,
      encuesta_placer: "",
      encuesta_depresion: "",
      encuesta_dificultad_laboral: "",
      encuesta_depresion_medico: "",
      encuesta_red_apoyo: "",
      date: new Date().toISOString().substring(0, 10),
      FRECUENCIA: [
        "Ningún día",
        "Varios días",
        "Más de la mitad de los días",
        "Casi todos los días",
      ],
      FECHA_DIAGNOSTICO: [
        "Nunca",
        "Hace un año o menos",
        "Entre los últimos 2 años",
        "Hace más de 2 años",
      ],
      COTIDIANIDAD: [
        "Ningún día",
        "Varios días",
        "Más de la mitad de los días",
        "Siempre o casi siempre",
      ],
      rules: [(v) => !!v || "Falta la respuesta a esta pregunta"],
    };
  },
  methods: {
    async siguiente() {
      this.$refs.form.validate();
      const { valid, encuesta_placer, encuesta_depresion, encuesta_dificultad_laboral, encuesta_depresion_medico, encuesta_red_apoyo, date } = this;
      if (valid) {
        const pacienteId = this.$route.params["id"];
        // Accede a la lista de pacientes
        const pacientes = this.$store.state.patients.patients;

        // Encuentra el paciente cuyo codigoconasida coincida con el ID de la ruta
        const paciente = pacientes.find(p => p.codigoconasida === pacienteId);

        const rut = paciente.rut

        const payload = {
                    paciente_nombre: rut,
                    register_poll_date: date,
                    Encuesta_1: encuesta_placer,
                    Encuesta_2: encuesta_depresion,
                    Encuesta_3: encuesta_dificultad_laboral,
                    Encuesta_4: encuesta_depresion_medico,
                    Encuesta_5: encuesta_red_apoyo,
                    method: "POST",
                };
        try {
            await this.$store.dispatch("patients/addEncuestaTras", payload);
        } catch (error) {
            console.log("Algo falló: ", error);
        }
      } else {
        console.log("no valido");
      }
    },
  },
};
</script>
